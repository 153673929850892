import { Component, Input } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ClusivityListItemsComponent } from '../clusivity-list-items.component';

@Component({
  selector: 'app-clusivity-list-multi-text-input',
  templateUrl: './clusivity-list-multi-text-input.component.html',
  styleUrls: ['./clusivity-list-multi-text-input.component.less'],
  providers: [{
    provide: ClusivityListItemsComponent,
    useExisting: ClusivityListMultiTextInputComponent
  }]
})
export class ClusivityListMultiTextInputComponent<TItem> extends ClusivityListItemsComponent<TItem> {
  @Input() placeHolder = '';
  @Input() maxTagCount = 2;
  @Input() selectElementId = '';
  isCopiedItems = false;
  selectIcons: { [p: string]: boolean } = {};

  constructor(private clipboardService: ClipboardService,
    private notification: NzNotificationService,
  ){
    super();
  }

  public clipboardCopy(text: string, inlineNotification = false): void {
    this.clipboardService.copy(text);
    if (inlineNotification) {
      this.isCopiedItems = true;
      setTimeout(() => {
        this.isCopiedItems = false;
      }, 1500);
    } else {
      this.notification.success('Success', 'Copied to clipboard.');
    }
  }
}
