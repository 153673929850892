<ng-container>
    <div class="sectionTitle"><fa-icon icon="users-cog"></fa-icon>Users Management</div>
     <nz-tabset #usersTabs
              *ngIf="users.length"
              [nzTabPosition]="'left'"
              nzType="line"
              [nzSelectedIndex]="selectedUserIndex"
              [nzTabBarExtraContent]="addUsersButtons">
  
  
        <nz-tab #userTab
                *ngFor="let user of users; let i = index"
                [nzTitle]="userTabTitle"
                (nzClick)="selectedUserIndex = i">
  
  
                <!-- TAB TITLE/BUTTON -->
                <ng-template #userTabTitle>
                  <div class="display-flex align-items-center justify-content-center">
                    <span style="margin-right: 6px; text-transform: capitalize"
                      [ngClass]="{'has-error': (!user.toBeRemoved && user.form && user.form.invalid) }">
                      {{ getUserName(user) }}
                    </span>
                    <fa-icon icon="trash"
                            *ngIf="!user.id"
                            [style.color]="selectedUserIndex === i ? '' : '#bfbfbf'"
                            class="remove-unsaved-user-btn"
                            nz-tooltip
                            [nzTooltipTitle]="'Remove unsaved user'"
                            (click)="onRemoveUnsavedUser(i)">
                    </fa-icon>
                    <div class="to-be-deleted-stamp" *ngIf="user.toBeRemoved">TO BE DELETED</div>
                  </div>
                </ng-template>
  
                <!-- USER ACTIONS (MARK/UNMARK USER FOR DELETE) -->
                <div class="user-actions" *ngIf="selectedUserIndex !== null && users[selectedUserIndex].id">
                  <button type="button"
                          nz-button
                          nzBlock
                          nz-tooltip
                          [nzTooltipTitle]="user.toBeRemoved ? 'Undo mark' : 'Mark for deletion (applies only after saving the ' + entityName + ')' "
                          class="secondary-button red-button small-font small-height small-padding"
                          id="remove_user_button"
                          (click)="$event.preventDefault(); toggleUserDeleteStatus(selectedUserIndex)">
                    {{ user.toBeRemoved ? 'Undo Delete User' : 'Delete User' }}
                  </button>
                </div>
  
                <!-- TAB CONTENT -->
                <div [ngClass]="{'active': user.toBeRemoved}" class="disabled-overlay">
                  <app-user-management-update *ngIf="user"
                                              [isParentPublisherEdit]="true"
                                              [publisherAccountType]="publisher?.publisherInfo?.publisherAccountType"
                                              [updateOtherUserMode]="true"
                                              [userInput]="user"
                                              [publisher]="entity"
                                              [entityType]="entityType"
                                              (userOutput)="onUserFormChange($event, user)">
                  </app-user-management-update>
                </div>
  
        </nz-tab>
  
        <!-- ADD USER BUTTON -->
        <ng-template #addUsersButtons>
          <button nz-button class="add-button secondary-button small-font margin-top-1" id="add_user_button" (click)="$event.preventDefault(); addUser()">
            Add User
          </button>
          <button nz-button [disabled]="hasApiUser()"
            class="add-button secondary-button small-padding smaller-font bold-font whitespace-prewrap margin-top-1"
            id="add_ext_user_button" (click)="$event.preventDefault(); addUser('API')">
            Generate API User
          </button>
        </ng-template>
  
  
    </nz-tabset>
  
    <!-- IN CASE THE ENTITY HAS NO USERS (THIS SHOULD NOT HAPPEN, BUT.. JUST IN CASE) - BANNER WITH ADD USER BUTTON -->
    <nz-spin [nzSpinning]="areUsersLoading" class="padding-bottom-2 padding-top-2" nzSize="large" nzTip='Fetching users...'></nz-spin>
  
    <nz-alert *ngIf="!areUsersLoading && users.length === 0" [nzMessage]="noUsersMessage" nzType="info"></nz-alert>
    <ng-template #noUsersMessage>
      <span>No users connected to this {{ entityName }}.</span>
      <button type="button" nz-button nzBlock class="add-first-user-button secondary-button small-font" id="add_first_user_btn" (click)="$event.preventDefault(); addUser()">
        Add User
      </button>
    </ng-template>
  
  
  </ng-container>
