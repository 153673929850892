import { AbstractControl, ValidatorFn } from '@angular/forms';
import { GeoTargetingForm } from './geo-targeting.models';

export const forbiddenCharValidator: ValidatorFn = (control: AbstractControl<GeoTargetingForm>): { [key: string]: any } | null => {
  if (control){
    const geoTargeting = control.value;
    const zipCodes = geoTargeting?.zipCodesClusiveList?.items;
    if (!zipCodes || !Array.isArray(zipCodes)) {
      return null;  // If the value is not an array or undefined, consider it valid
    }
    const isStringValid = zipCodes.every((value: string) => /^[a-zA-Z0-9- ]*$/.test(value));
    return !isStringValid ? { specialCharsErrorMsg: true } : null;
  }
  return null;
};
