<div id="app-table">

  <div class="table-container" fixedTableHeader [isExternal]="isExternal">

    <nz-table
      #appTable
      [nzData]="tableData"
      [nzLoading]="isTableLoading"
      [nzScroll]="scrollConfig"
      [nzPageSize]="table.pageSize"
      [nzShowPagination]="isPaginated"
      [nzShowSizeChanger]="hasSizeChanger"
      [nzFooter]="hasFooter && (totalRows > 0) ? footer : null"
      [nzFrontPagination]="tableType === 'clientSide'"
      [ngClass]="table.tableClass"
      id="{{table.id}}">

      <thead (nzSortOrderChange)="onTableSortChange($event)">
      <tr>
        <ng-container *ngFor="let column of tableColumns; let idx = index">
          <ng-container [ngSwitch]="column.type">
            <ng-container *ngSwitchCase="'selections'">
              <th
                nzShowCheckbox
                nzShowRowSelection
                [nzChecked]="selections.isSelectAll &&
                               selections.selectedItems.length === 0 &&
                               selections.excludedItems.length === 0"
                [nzIndeterminate]="selections.selectedItems.length > 0 || selections.excludedItems.length > 0"
                (nzCheckedChange)="selectAll()">
              </th>
            </ng-container>
            <ng-container *ngSwitchCase="'expand-row'">
              <th [nzWidth]="column.colWidth"></th>
            </ng-container>
            <ng-container *ngSwitchCase="'actions'">
              <th *ngIf="hasPermissions() || isExternal" nzRight="0px">
                {{ (isExternal && column.externalDisplayName) ? column.externalDisplayName :
                (column.displayNameFormatter ? getDisplayNameFormatterValue(column) : column.displayName) }}
              </th>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <th *ngIf="!column.isHidden" #thCell
                  [nzColumnKey]="column.sortingProp ||  column.prop"
                  [nzShowSort]="column.isSortEnabled"
                  [nzSortFn]="true"
                  [nzSortOrder]="(column.sortingProp || column.prop) === table.sortBy ?
                                 (table.sortDirection === 'ASC' ? 'ascend' :
                                  table.sortDirection === 'DESC' ? 'descend' : null) :
                                 null"
                  [nzWidth]="column?.colWidth"
                  [style.maxWidth]="column.maxWidth ? column.maxWidth : 'inherit'"
                  [nzLeft]="getStickyWidthLeft(idx)"
                  [@freezeColumn]="column.isFrozenCol ? 'freeze' : 'unfreeze'"
                  [ngClass]="column.extraClass ? column.extraClass : ''"
                  nz-tooltip [nzTooltipTitle]="column.tooltip ? column.tooltip : ''">
                {{ (isExternal && column.externalDisplayName) ? column.externalDisplayName :
                (column.displayNameFormatter ? getDisplayNameFormatterValue(column) : column.displayName) }}
              </th>
            </ng-container>
          </ng-container>
        </ng-container>
      </tr>
      <tr *ngIf="isTableLoading && (!tableData || tableData?.length === 0)">
        <td>
          <div style="min-height: 250px"></div>
        </td>
      </tr>
      </thead>
      <tbody>

      <ng-container *ngFor="let row of appTable.data; let rowIndex=index">
        <ng-container *ngIf="row.trComponent; else tr">
          <tr>
            <td [colSpan]="tableColumns.length">
              <ng-template customComponentHost></ng-template>
            </td>
          </tr>
        </ng-container>
        <ng-template #tr>
          <tr class="table-actions" id="{{table.id + '-tr-' + row[idProp]}}" [ngClass]="{'expand-row-open': row.isExpanded}">
            <ng-container *ngFor="let column of tableColumns; let colIndex = index">
              <ng-container [ngSwitch]="column.type">
                <ng-container *ngSwitchCase="'selections'">
                  <td nzShowCheckbox
                      [nzDisabled]="row.isDisabled"
                      (click)="$event.stopPropagation()"
                      [nzChecked]="isRowSelected(row)"
                      (nzCheckedChange)="updateSelectedObjects(row)">
                  </td>
                </ng-container>
                <ng-container *ngSwitchCase="'toggle'">
                  <td>
                    <nz-switch [nzDisabled]="!authPermissions['canUpdate']" nzCheckedChildren="Active" nzUnCheckedChildren="Inactive"
                               [ngModel]="row.status === 'ACTIVE'"
                               (ngModelChange)="onRowAction(row, row[idProp], rowIndex, column.actionId)"
                    >
                    </nz-switch>
                  </td>
                </ng-container>
                <ng-container *ngSwitchCase="'toggle-publisher-status'">
                  <td>
                    <nz-switch [nzDisabled]="!authPermissions['canUpdate'] || row.accountingId === null" nz-tooltip 
                    [nzTooltipTitle]="row.accountingId === null ? 'NetSuite ID is mandatory for publisher activation' : null"
                               nzCheckedChildren="Active" nzUnCheckedChildren="Inactive"
                               [ngModel]="row.status === 'ACTIVE'"
                               (ngModelChange)="onRowAction(row, row[idProp], rowIndex, column.actionId)"
                    >
                    </nz-switch>
                  </td>
                </ng-container>
                <ng-container *ngSwitchCase="'toggle-alert'">
                  <td>
                    <nz-switch
                               class="small-toggle"
                               [disabled]="true"
                               [ngModel]="row.status === 'ACTIVE'"
                               (ngModelChange)="onRowAction(row, row[idProp], rowIndex, column.actionId)"
                    >
                    </nz-switch>
                  </td>
                </ng-container>
                <ng-container *ngSwitchCase="'toggle-domain'">
                  <td>
                    <nz-switch nzCheckedChildren="Yes" nzUnCheckedChildren="No" [ngModel]="row.includeSubDomains"
                               [id]="'domain-bundle-subdomains-' + row[idProp]"
                               (ngModelChange)="onRowAction(row, row[idProp], rowIndex, column.actionId)">
                    </nz-switch>
                  </td>
                </ng-container>
                <ng-container *ngSwitchCase="'toggle-deal'">
                  <td>
                    <nz-switch nzCheckedChildren="Active"
                               nzUnCheckedChildren="Inactive"
                               [ngModel]="row.status === 'ACTIVE'"
                               [disabled]="column.valueFormatter(row, 'disabled')"
                               nz-tooltip nzTooltipPlacement="bottom"
                               [nzTooltipTitle]="column.valueFormatter(row, 'nzTooltipTitle')"
                               (ngModelChange)="onRowAction(row, row[idProp], rowIndex, column.actionId)">
                    </nz-switch>
                  </td>
                </ng-container>
                <ng-container *ngSwitchCase="'toggle-exchange-deal'">
                  <td>
                    <nz-switch nzCheckedChildren="Active"
                               nzUnCheckedChildren="Inactive"
                               [ngModel]="row.status === 'active'"
                               [disabled]="true" >
                    </nz-switch>
                  </td>
                </ng-container>
                <ng-container *ngSwitchCase="'toggle-exchange-partner'">
                  <td *ngIf="!column.isHidden">
                    <nz-switch
                      [ngModel]="column.toggleConfig?.checked(row)"
                      (ngModelChange)="onRowAction(row, row[idProp], rowIndex, column.actionId)"
                      [nzCheckedChildren]="'Active'"
                      [nzUnCheckedChildren]="'Inactive'"
                      [nzDisabled]="column.toggleConfig?.disabled">
                    </nz-switch>
                  </td>
                </ng-container>
                <ng-container *ngSwitchCase="'expand-row'">
                  <ng-container *ngIf="row[row['expandProp']] || row.expandRowComponent; else noExpandIcon">
                    <td nzShowExpand [(nzExpand)]="row.isExpanded" (nzExpandChange)="$event ? loadCustomComponent(row, rowIndex, 'expandRowComponent') : false" [ngClass]="{'expandDisabled': row.isExpandDisabled}"></td>
                  </ng-container>
                  <ng-template #noExpandIcon>
                    <td></td>
                  </ng-template>
                </ng-container>
                <ng-container *ngSwitchCase="'tags'">
                  <td>
                    <ng-container *ngFor="let tag of column.tags">
                      <nz-tag *ngIf="row.tag === tag.condition"
                              [ngClass]="row.tag === 'DELETED' ? 'red' : row.tag === 'inactive' ? 'inactive-status' : 'active-status'"
                              nz-tooltip nzTooltipPlacement="bottom" [nzTooltipTitle]="tag.tooltip">
                        {{tag.name}}
                      </nz-tag>
                    </ng-container>
                  </td>
                </ng-container>
                <ng-container *ngSwitchCase="'tags-rx-dashboard'">
                  <td>
                      <ng-container *ngFor="let tag of column.tags">
                      <nz-tag *ngIf="row[column.prop] === tag.condition"
                              [ngClass]="row[column.prop] === 'inactive' ? 'inactive-status' : 'active-status'"
                              nz-tooltip nzTooltipPlacement="bottom" [nzTooltipTitle]="tag.tooltip">
                        {{tag.name}}
                      </nz-tag>
                    </ng-container>
                  </td>
                </ng-container>
                <ng-container *ngSwitchCase="'actions'">
                  <td *ngIf="hasPermissions() || isExternal" class="app-table-actions-col divideContent whitespace-nowrap"
                      nzRight="0px">
                    <ng-container *ngFor="let action of getVisibleActions(row, column.actions)">
                      <ng-container [ngSwitch]="action.name">
                        <ng-container *ngSwitchCase="'delete'">
                          <a *ngIf="authPermissions[action.auth] && !row.deletedAt && !row.deleted_at"
                             [ngClass]="{'disabled-click': row.deleted_at || row.deletedAt}"
                             (click)="$event.stopPropagation(); onShowConfirmDialog(row[idProp])"
                             nz-popconfirm [nzPopconfirmTitle]="deletePopconfirmTitleTpl"
                             (nzOnConfirm)="onRowAction(row, row[idProp], rowIndex, action.id)"
                             (nzOnCancel)="onShowConfirmDialog(null)"
                             nzOkText="Delete"
                             id="{{table.id + '-' + action.name + '-' + row[idProp]}}">
                            {{action.displayName}}
                          </a>
                          <ng-template #deletePopconfirmTitleTpl>
                            <fa-icon icon="exclamation-circle" class="font-icon-color-warning-mid-dark margin-right-small"></fa-icon>
                            {{action.desc}}
                          </ng-template>
                        </ng-container>

                        <ng-container *ngSwitchCase="'new-delete'">
                          <a *ngIf="authPermissions[action.auth] && !row.deletedAt && !row.deleted_at"
                             [ngClass]="{'disabled-click': row.deleted_at || row.deletedAt}"
                             (click)="onRowAction(row, row[idProp], rowIndex, action.id)"
                             id="{{table.id + '-' + action.name + '-' + row[idProp]}}">
                            {{action.displayName}}
                          </a>
                        </ng-container>

                        <ng-container *ngSwitchCase="'view'">
                          <a *ngIf="!authPermissions['canUpdate'] && !isExternal"
                             [routerLink]="action.hrefLink ? row[action.hrefIdProp] + action.hrefLink : ''"
                             [ngClass]="{'disabled-click': row.deleted_at || row.deletedAt }"
                             id="{{table.id + '-' + action.name + '-' + row[idProp]}}"
                             (click)="!action.hrefLink && onRowAction(row, row[idProp], rowIndex, action.id)">
                            {{action.displayName}}
                          </a>
                        </ng-container>

                        <ng-container *ngSwitchCase="'edit'">
                          <a *ngIf="authPermissions[action.auth] && !isExternal"
                             [routerLink]="action.hrefLink ? row[action.hrefIdProp] + action.hrefLink : ''"
                             [ngClass]="{'disabled-click': row.deleted_at || row.deletedAt }"
                             id="{{table.id + '-' + action.name + '-' + row[idProp]}}"
                             (click)="!action.hrefLink && onRowAction(row, row[idProp], rowIndex, action.id)">
                            {{action.displayName}}
                          </a>
                          <a *ngIf="authPermissions[action.auth] && isExternal"
                             [routerLink]="action.hrefLink ? row[action.hrefIdProp] + action.hrefLink : ''"
                             id="{{table.id + '-' + action.name + '-' + row[idProp]}}"
                             (click)="!action.hrefLink && onRowAction(row, row[idProp], rowIndex, action.id)">
                            {{action.displayName}}
                            <span class="margin-left-small" *ngIf="action.icon"><fa-icon icon="edit"></fa-icon></span>
                          </a>
                        </ng-container>

                        <ng-container *ngSwitchCase="'edit-restore'">
                          <a *ngIf="authPermissions[action.auth] && !isExternal"
                             [routerLink]="action.hrefLink ? row[action.hrefIdProp] + action.hrefLink : ''"
                             id="{{table.id + '-' + action.name + '-' + row[idProp]}}"
                             (click)="!action.hrefLink && onRowAction(row, row[idProp], rowIndex, action.id)">
                            {{action.displayName}}
                          </a>
                        </ng-container>

                        <ng-container *ngSwitchCase="'view-deleted'">
                          <a *ngIf="authPermissions[action.auth] && (row.deletedAt || row.deleted_at)"
                             [routerLink]="action.hrefLink ? row[action.hrefIdProp] + action.hrefLink : ''"
                             [ngClass]="{'disabled-click': !(row.deleted_at || row.deletedAt)}"
                             id="{{table.id + '-' + action.name + '-' + row[idProp]}}"
                             (click)="!action.hrefLink && onRowAction(row, row[idProp], rowIndex, action.id)">
                            {{action.displayName}}
                          </a>
                          <a *ngIf="authPermissions[action.auth] && isExternal"
                             [routerLink]="action.hrefLink ? row[action.hrefIdProp] + action.hrefLink : ''"
                             id="{{table.id + '-' + action.name + '-' + row[idProp]}}"
                             (click)="!action.hrefLink && onRowAction(row, row[idProp], rowIndex, action.id)">
                            {{action.displayName}}
                            <span class="margin-left-small" *ngIf="action.icon"><fa-icon icon="edit"></fa-icon></span>
                          </a>
                        </ng-container>

                        <ng-container *ngSwitchCase="'duplicate'">
                          <a *ngIf="authPermissions[action.auth] && !isExternal"
                             [ngClass]="{'disabled-click': row.deleted_at}"
                             id="{{table.id + '-' + action.name + '-' + row[idProp]}}"
                             (click)="onRowAction(row, row[idProp], rowIndex, action.id)">
                            {{action.displayName}}
                          </a>
                          <a *ngIf="authPermissions[action.auth] && isExternal" nzSize="small"
                             class=""
                             id="{{table.id + '-' + action.name + '-' + row[idProp]}}"
                             (click)="onRowAction(row, row[idProp], rowIndex, action.id)">
                            {{action.displayName}}
                            <span class="margin-left-small" *ngIf="action.icon"><fa-icon icon="edit"></fa-icon></span>
                          </a>
                        </ng-container>

                        <ng-container *ngSwitchCase="'selectButton'">
                          <a *ngIf="authPermissions[action.auth] && !isExternal"
                             id="{{table.id + '-' + action.name + '-' + row[idProp]}}"
                             (click)="onRowAction(row, row[idProp], rowIndex, action.id)">
                            {{action.displayName}}
                          </a>
                          <a *ngIf="authPermissions[action.auth] && isExternal" nzSize="small"
                             id="{{table.id + '-' + action.name + '-' + row[idProp]}}"
                             (click)="onRowAction(row, row[idProp], rowIndex, action.id)">
                            {{action.displayName}}<span class="margin-left-small" *ngIf="action.icon"><fa-icon
                            icon="check"></fa-icon></span>
                          </a>
                        </ng-container>

                        <ng-container *ngSwitchDefault>
                          <a *ngIf="authPermissions[action.auth] || isExternal"
                             id="{{table.id + '-' + action.name + '-' + row[idProp]}}"
                             [class]="action.class ? action.class : ''"
                             (click)="onRowAction(row, row[idProp], rowIndex, action.id)">
                            {{action.displayName}}
                          </a>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </td>
                </ng-container>

                <ng-container *ngSwitchDefault [ngSwitch]="column.type">
                  <td *ngIf="!column.isHidden" #tdCell
                      [style.maxWidth]="column.maxWidth ? column.maxWidth : 'inherit'"
                      [nzLeft]="getStickyWidthLeft(colIndex)"
                      [@freezeColumn]="column.isFrozenCol ? 'freeze' : 'unfreeze'"
                      [title]="column.maxWidth ? row[column.prop] : ''"
                      [ngClass]="column.extraClass ? column.extraClass : ''"
                      id="{{table.id + '-' + column.prop + '-' + row[column.prop]}}">

                    <ng-container *ngSwitchCase="'commentTag'">
                      <span>{{row[column.prop]}}</span>
                      <nz-tag *ngIf="row[column.commentTag.checkProp]"
                              [nzColor]="'red'"
                              class="margin-left-small"
                              nz-tooltip="{{column.commentTag.tooltip}}">
                        Deleted
                      </nz-tag>
                    </ng-container>

                    <ng-container *ngSwitchCase="'moreTag'">
                      <ng-container *ngIf="row[column.prop]">
                        <span [ngClass]="{'underlined-link-button': column.moreTag?.linked}"
                              (click)="routeIf(column.valueFormatter(row[column.prop][0]?.pubDealId ?? row[column.prop][0]?.packageId), column.moreTag?.openTab)">
                          {{(row[column.prop] && row[column.prop][0]) ? lodashGet(row[column.prop][0], column.moreTag.displayNameProp, column.id) : ''}}
                          <ng-container *ngTemplateOutlet="td_additional_name_property; context: {
                            column: column,
                            additionalName: lodashGet(row[column.prop][0], column.moreTag.additionalNameProperty)
                          }"></ng-container>
                        </span>
                        <nz-tag *ngIf="row[column.prop]?.length > 1"
                                class="margin-left-small"
                                nz-popover nzPopoverTitle="{{column.moreTag.popoverTitle}}"
                                [nzPopoverContent]="moreList"
                                nzPopoverTrigger="hover">
                          {{row[column.prop]?.length - 1}} more
                        </nz-tag>
                        <ng-template #moreList>
                          <div class="display-flex flex-column">
                            <ng-container *ngFor="let item of row[column.prop]; let i = index; let isLast = last">
                              <span *ngIf="i > 0" [ngClass]="{
                                'underlined-link-button': column.moreTag?.linked,
                                'margin-bottom-small': !isLast
                              }" (click)="routeIf(column.valueFormatter(item.pubDealId ?? item.packageId), column.moreTag?.openTab)">
                                {{ lodashGet(item, column.moreTag.displayNameProp) }}
                                <ng-container *ngTemplateOutlet="td_additional_name_property; context: {
                                  column: column,
                                  additionalName: lodashGet(row[column.prop][i], column.moreTag.additionalNameProperty)
                                }"></ng-container>
                              </span>
                            </ng-container>
                          </div>
                        </ng-template>
                      </ng-container>
                      <ng-container *ngIf="!row[column.prop]?.length">
                        <div class="low-opacity" *ngIf="column.moreTag.noDataMessage">
                          {{column.moreTag.noDataMessage}}
                        </div>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'date'">
                      <ng-container *ngTemplateOutlet="td_date; context: { row: row, column: column }"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'date-and-time'">
                      <ng-container
                        *ngTemplateOutlet="td_date_and_time; context: { row: row, column: column }"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'date-and-time-timestamp-to-est'">
                      <ng-container
                        *ngTemplateOutlet="td_date_and_time_timestamp_to_est; context: { row: row, column: column }"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'date-est'">
                      <ng-container *ngTemplateOutlet="td_date_est; context: { row: row, column: column }"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'date-and-time-est'">
                      <ng-container
                        *ngTemplateOutlet="td_date_and_time_est; context: { row: row, column: column }"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'date-full-month-year'">
                      <ng-container
                        *ngTemplateOutlet="td_date_full_month_and_year; context: { row: row, column: column }"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'date-full-month-day-year'">
                      <ng-container
                        *ngTemplateOutlet="td_date_full_month_day_and_year; context: { row: row, column: column }"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'currency'">
                      <ng-container *ngTemplateOutlet="td_currency; context: { row: row, column: column }"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'percent'">
                      <ng-container *ngTemplateOutlet="td_percent; context: { row: row, column: column }"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'percent-format'">
                      <ng-container
                        *ngTemplateOutlet="td_percent_format; context: { row: row, column: column }"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'number-with-commas'">
                      <ng-container
                        *ngTemplateOutlet="td_number_with_commas; context: { row: row, column: column }"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'titlecase-text'">
                      <ng-container
                        *ngTemplateOutlet="td_title_case; context: { row: row, column: column }"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'status-success-failed'">
                      {{lodashGet(row, column.prop) ? 'Success' : 'Failed'}}
                    </ng-container>
                    <ng-container *ngSwitchCase="'custom-function'">
                      <span class="whitespace-nowrap">{{ column.valueFormatter(lodashGet(row, column.prop), row, column) }}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'link'">
                      <a *ngIf="column.valueFormatter(row) as link; else notALink" [routerLink]="link">{{row[column.prop]}}</a>
                      <ng-template #notALink>{{row[column.prop]}}</ng-template>
                    </ng-container>
                    <ng-container *ngSwitchCase="'text-with-tooltip'">
                              <span class="margin-right-1">{{row[column.prop]}}</span>
                              <span nz-tooltip [nzTooltipTitle]="column.valueFormatter(row)">
                                <fa-icon class="fa-md font-icon-color-grey-cool font-icon-hover-color-primary-main"
                                         icon="question-circle"></fa-icon>
                              </span>
                    </ng-container>

                    <ng-container *ngSwitchCase="'link-or-tag'">
                      <ng-container *ngFor="let item of row[column.prop]; let first = first">
                        <ng-container *ngIf="column.valueFormatter(item) as val">
                          <ng-container [ngSwitch]="val.type">
                            <ng-container *ngSwitchCase="'link'">
                              <a class="underlined-link-button display-block" [routerLink]="val.routerLink">{{val.value}}</a>
                            </ng-container>
                            <ng-container *ngSwitchCase="'tag'">
                              <span nz-tooltip nzTooltipPlacement="top" [nzTooltipTitle]="val.tooltip">
                                <span style="color: #12100B66;">{{val.value}}</span><br>
                                <nz-tag class="red">{{val.tagText}}</nz-tag>
                              </span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'text'">
                              {{val.value}}
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchDefault [ngSwitch]="column.id">
                      <ng-container *ngSwitchCase="'inventoryPackagesPublisherName'">
                        <span>{{row["publisher"] ? row["publisher"]["publisherName"] : ''}}</span>
                      </ng-container>
                      <ng-container *ngSwitchCase="'inventoryPackagesPackageName'">
                        <div nz-row class="flex-nowrap">
                          <div class="ellipsis" title="{{row[column.prop]}}">{{row[column.prop]}}</div>
                          <div class="font-icon-color-grey-iron font-icon-hover-color-grey-graphite cursor-pointer margin-left-small"
                               nz-tooltip="Copy package name"
                               (click)="onRowAction(row, row[idProp], rowIndex, 'inventoryPackageCopyName')">
                            <fa-icon icon="copy"></fa-icon>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngSwitchCase="'dealsExternalDealId'">
                        <div nz-row class="flex-nowrap">
                          <div class="ellipsis" title="{{row[column.prop]}}">{{row[column.prop]}}</div>
                          <div class="font-icon-color-grey-iron font-icon-hover-color-grey-graphite cursor-pointer margin-left-small"
                               nz-tooltip="Copy deal id"
                               (click)="onRowAction(row, row[idProp], rowIndex, 'dealCopyId')">
                            <fa-icon icon="copy"></fa-icon>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngSwitchCase="'publisherAuditTimestamp'">
                          <span>{{row[column.prop] }}</span>
                          <ng-container *ngIf="lodashGet(row, 'username') === 'SYSTEM'">
                            <span class="whitespace-nowrap">
                              <fa-icon class="fa-md font-icon-color-grey-cool font-icon-hover-color-primary-main margin-left-small"
                                icon="info-circle" 
                                nz-tooltip 
                                nzTooltipTitle="This change refers to a manual system update, which was performed before current change saved in CTRL."
                              ></fa-icon>
                            </span>
                          </ng-container>
                      </ng-container>
                      <ng-container *ngSwitchCase="'publisherAuditField'">
                        <span>{{row[column.prop] | formatText: {
                          'camelCaseToWords': true
                        } }}</span>
                    </ng-container>
                      <ng-container *ngSwitchCase="'publisherAuditParent'">
                          <span>{{row[column.prop] | formatText: {
                            'camelCaseToWords': true
                          } }}</span>
                      </ng-container>
                      <ng-container *ngSwitchCase="'publisherAuditPreviousValue'">
                          <span [nzTooltipTitle]="row[column.prop]?.length > 40 ? row[column.prop] : ''" nz-tooltip
                                nzTooltipPlacement="bottom">
                            {{row[column.prop] | enums | formatText: {
                            'enumToTitleCase': true,
                            'truncateText': 40
                          } }}</span>
                      </ng-container>
                      <ng-container *ngSwitchCase="'publisherAuditNewValue'">
                          <span [nzTooltipTitle]="row[column.prop]?.length > 40 ? row[column.prop] : ''" nz-tooltip
                                          nzTooltipPlacement="bottom">
                            {{row[column.prop] | enums | formatText: {
                            'enumToTitleCase': true,
                            'truncateText': 40
                          } }}</span>
                      </ng-container>
                      <ng-container *ngSwitchCase="'exchangePadsAgreementType'">
                          <span>{{row[column.prop]}}</span>
                      </ng-container>

                      <ng-container *ngSwitchDefault>
                        {{lodashGet(row, column.prop) | enums}}
                      </ng-container>
                    </ng-container>
                  </td>
                </ng-container>
              </ng-container>
            </ng-container>
          </tr>
        </ng-template>

        <tr [nzExpand]="row.isExpanded" *ngIf="row[row['expandProp']] || row.expandRowComponent">
          <ng-container *ngIf="row.expandRowComponent?.component; else expandRowInnerTable">
            <ng-template customComponentHost></ng-template>
          </ng-container>
          <ng-template #expandRowInnerTable>
            <app-table [tableColumns]="innerTableColumns"
                       [innerTableColumns]="innerTableColumns"
                       [table]="table"
                       [tableData]="row[row['expandProp']]">
            </app-table>
          </ng-template>
        </tr>
      </ng-container>

      <ng-container *ngIf="tableTotalsData">
        <tr class="table-totals" id="{{table.id + '-tr-total'}}">
          <ng-container *ngFor="let column of tableColumns; let idx = index" [ngSwitch]="column.type">
            <td *ngIf="!column.isHidden" #tdTotalCell
                [nzLeft]="getStickyWidthLeft(idx)">
              <ng-container *ngSwitchCase="'currency'">
                <ng-container
                  *ngTemplateOutlet="td_currency; context: { row: tableTotalsData, column: column }"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'percent'">
                <ng-container
                  *ngTemplateOutlet="td_percent; context: { row: tableTotalsData, column: column }"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'percent-format'">
                <ng-container
                  *ngTemplateOutlet="td_percent_format; context: { row: tableTotalsData, column: column }"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'number-with-commas'">
                <ng-container
                  *ngTemplateOutlet="td_number_with_commas; context: { row: tableTotalsData, column: column }"></ng-container>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <span>-</span>
              </ng-container>
            </td>
          </ng-container>
        </tr>
      </ng-container>

      </tbody>
    </nz-table>
  </div>
</div>

<ng-template #footer>
  <div class="app-table-footer">
    <nz-pagination *ngIf="totalRows"
                   id="table-pagination"
                   class="paginator"
                   [nzTotal]="totalRows"
                   [nzPageIndex]="table.pageIndex"
                   [nzPageSize]="table.pageSize"
                   [nzShowSizeChanger]="table ? table.hasPageSizeChanger : false"
                   [nzPageSizeOptions]="table.pageSizeOptions"
                   (nzPageIndexChange)="onPageIndexChanged($event)"
                   (nzPageSizeChange)="onPageSizeChanged($event)">
    </nz-pagination>
    <div class="floated-excel" *ngIf="exportCSV && (tableData && tableData?.length > 0)">
      <button nz-button id="download-button-table-{{table.id}}" class="secondary-button"
              (click)="onExportClicked($event)"
              [nzLoading]="isDownloading"
              [disabled]="isTableLoading && (!tableData || tableData?.length === 0)">
        Download CSV
      </button>
    </div>
  </div>
</ng-template>


<ng-template #td_date let-row="row" let-column="column">
  <span>{{row[column.prop] | date: 'MM/dd/yyyy'}}</span>
</ng-template>

<ng-template #td_date_and_time let-row="row" let-column="column">
  <span>{{row[column.prop] | date: 'MM/dd/yyyy h:mm a'}}</span>
</ng-template>

<ng-template #td_date_and_time_timestamp_to_est let-row="row" let-column="column">
  <span>{{common.convertTimestampToEstDateTime(row[column.prop])}}</span>
</ng-template>

<ng-template #td_date_est let-row="row" let-column="column">
  <span>{{row[column.prop] | date: 'MM/dd/yyyy'}} (EST)</span>
</ng-template>

<ng-template #td_date_and_time_est let-row="row" let-column="column">
  <span>{{row[column.prop] | date: 'MM/dd/yyyy h:mm a'}} (EST)</span>
</ng-template>

<ng-template #td_date_full_month_and_year let-row="row" let-column="column">
  <span>{{row[column.prop] | date: 'MMM yyyy'}}</span>
</ng-template>

<ng-template #td_date_full_month_day_and_year let-row="row" let-column="column">
  <span>{{row[column.prop] | date: 'MMM d, yyyy'}}</span>
</ng-template>

<ng-template #td_currency let-row="row" let-column="column">
  <span>{{row[column.prop] | currency:(column.currency ? column.currency : row.currency):'symbol-narrow'}}</span>
</ng-template>

<ng-template #td_percent let-row="row" let-column="column">
  <span>{{row[column.prop] | percent}}</span>
</ng-template>

<ng-template #td_percent_format let-row="row" let-column="column">
  <span>{{row[column.prop]}}%</span>
</ng-template>

<ng-template #td_number_with_commas let-row="row" let-column="column">
  <span>{{lodashGet(row, column.prop) | number:'1.0':'en-US'}}</span>
</ng-template>

<ng-template #td_title_case let-row="row" let-column="column">
  <span *ngIf="!column.prop.includes('.')">{{row[column.prop] | formatText: {
    'firstCapital': true,
    'removeUnderscore': true
  } }}</span>
  <span *ngIf="column.prop.includes('.')">{{lodashGet(row, column.prop) | formatText: {
    'firstCapital': true,
    'removeUnderscore': true
  } }}</span>
</ng-template>

<ng-template #td_additional_name_property let-column="column" let-additionalName="additionalName">
  <span *ngIf="column.moreTag.additionalNameProperty">
    <ng-container [ngSwitch]="column.id">
      <ng-container *ngSwitchCase="'inventoryPackagesDeals'">
        {{!isExternal && additionalName ? ' (ID: ' + additionalName + ')' : ''}}
      </ng-container>
      <ng-container *ngSwitchDefault>
        ({{additionalName}})
      </ng-container>
    </ng-container>
  </span>
</ng-template>


