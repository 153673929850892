import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { animate, style, transition, trigger } from '@angular/animations';

import { cloneDeep } from 'lodash';
import { combineLatest, forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, finalize, startWith, takeUntil, tap } from 'rxjs/operators';

import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ClipboardService } from 'ngx-clipboard';

import { FormHelper } from '../../../_common';
import {
  AppResponseHandler,
  CtrlUser,
  CtrlUserAuthorities,
  Currency,
  CurrentUser,
  PortalType,
  PublisherAccountType,
  Timezone,
  UserWithRoles
} from '../../_models/models';
import { SharedService } from '../../_services/shared.service';
import { ApiService } from '../../_services/api.service';
import { environment } from '../../../../environments/environment';
import { AlertsManagementComponent } from '../alerts-management/alerts-management.component';
import { AuthenticationService } from '../../../auth/_services/authentication.service';
import { SharedStoreService } from '../../_services/shared-store.service';

@Component({
  selector: 'app-user-management-update',
  templateUrl: './user-management-update.component.html',
  styleUrls: ['./user-management-update.component.less'],
  animations: [
    trigger('fadeOut', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.5s ease', style({ opacity: 0 }))
      ]),
    ]),
  ],
})

export class UserManagementUpdateComponent implements OnInit, OnDestroy {

  // both inputs are required for managing users as pubOps
  @Input() updateOtherUserMode = false;
  @Input() userInput: CtrlUser = null;
  @Input() isParentPublisherEdit = false;
  @Input() publisherAccountType: PublisherAccountType = null;
  @Input() publisher: any;
  @Input() entityType: 'PUBLISHER' | 'EXCHANGE_PARTNER';
  @Output() userOutput: EventEmitter<UntypedFormGroup> = new EventEmitter();
  @ViewChild(AlertsManagementComponent) alertsManagement: AlertsManagementComponent;

  form: UntypedFormGroup;
  user: CtrlUserAuthorities = null;
  user$: Observable<CtrlUser>;
  isJavaUserLoading$: Observable<boolean>;
  timezones$ = new AppResponseHandler<Timezone[]>();
  currencies$ = new AppResponseHandler<Currency[]>();

  isLoading = false;
  isSelectOpen = false;
  showChangePassword = false;
  isPasswordVisible = false;
  isConfPasswordVisible = false;
  isCopied_password = false;
  isCopied_passwordConfirmation = false;
  isGeneratingGatewayKey = false;
  isExchangeUser = false;
  isPartnerUser = false;
  env = environment;
  portalType: PortalType;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private fh: FormHelper,
    private location: Location,
    private router: Router,
    private notification: NzNotificationService,
    private authService: AuthenticationService,
    private api: ApiService,
    private sharedService: SharedService,
    private sharedStoreService: SharedStoreService,
    private activatedRoute: ActivatedRoute,
    private clipboardService: ClipboardService,
  ) { }

  get fc() {
    return this.form.controls;
  }

  ngOnInit() {
    this.initRouteResolve();
    this.initForm();
    if (this.updateOtherUserMode && this.userInput) {
      // init subscriptions without user$ (we're not editing the current logged in user)
      this.initSubscriptions(false);
      this.patchUserToForm(this.userInput);
      // When adding a new user (has no Id) - we make the password inputs always visible and required
      if (!this.userInput.id) {
        this.showChangePassword = true;
        this.form.get('firstName').setValue('');
        this.form.get('lastName').setValue('');
        this.form.get('email').enable();
        this.form.get('password').setValidators([Validators.required, this.sharedService.passwordValidator]);
        this.form.get('passwordConfirmation').setValidators([Validators.required]);
        this.form.updateValueAndValidity();
        if (this.userInput.type === 'API') {
          this.prepareApiUser();
        }
        this.verifiedChanges();
      } else {
        if (this.userInput.type === 'API') {
          this.form.get('firstName').disable();
          this.form.get('lastName').disable();
          this.form.updateValueAndValidity();
        }
      }
      this.intiFormOnChange();
    } else {
      this.initSubscriptions();
    }
  }

  verifiedChanges(): void {
    this.fc.verified.valueChanges.pipe(
      startWith(this.fc.verified.value),
      takeUntil(this.unsubscribe$)
    ).subscribe(val => {
      if (val) {
        this.form.get('password').enable();
        this.form.get('passwordConfirmation').enable();
        this.form.get('password').setValue('');
        this.form.get('passwordConfirmation').setValue('');
      } else {
        this.form.get('password').disable();
        this.form.get('passwordConfirmation').disable();
        this.form.get('password').setValue('Unnecessary_Pa$$w0rd');
        this.form.get('passwordConfirmation').setValue('Unnecessary_Pa$$w0rd');
      }
    });
  }

  initRouteResolve() {
    this.activatedRoute.data
      .subscribe((data) => {
        this.portalType = data.portalType;
      });
  }

  initForm() {
    this.form = this.fb.group({
      id: null,
      firstName: [{ value: '' }, [Validators.required, Validators.min(1)]],
      lastName: [{ value: '' }, [Validators.required, Validators.min(1)]],
      email: [{ value: '', disabled: true }, [Validators.required, Validators.email]],
      verified: [null],
      password: [null, [this.sharedService.passwordValidator]],
      passwordConfirmation: [null],
      timezone: this.fb.group({
        id: 1,
      }),
      currency: this.fb.group({
        id: 1,
      }),
      defaultUi: 'CTRL',
      gatewayApiKey: [{ value: null, disabled: true }],
      publisherId: [null],
      dealPartnerId: [null]
    }, {
      validators: this.sharedService.passwordConfirmationValidator
    });
  }

  intiFormOnChange() {
    this.form.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.emitFormChanges();
    });
  }

  emitFormChanges() {
    this.userOutput.emit(this.form);
  }

  initSubscriptions(isLoggedInUser = true) {
    this.timezones$.start(this.sharedStoreService.getSharedAsset('timezones')).subscribe();
    this.currencies$.start(this.sharedStoreService.getSharedAsset('currencies')).subscribe();
    if (isLoggedInUser) {
      this.user$ = this.authService.currentUser;
      this.user$.pipe(takeUntil(this.unsubscribe$)).subscribe(
        (res: CurrentUser) => {
          if (res !== null) {
            this.user = cloneDeep(res);
            // patch user values to form
            this.patchUserToForm(this.user);
            this.isExchangeUser = this.user?.roles.some(role => role === 'Exchange Business Team');
            this.isPartnerUser = this.portalType === 'partner';
          }
        },
      );
    }
  }

  patchUserToForm(user: CtrlUser) {
    combineLatest([
      this.timezones$.response$,
      this.currencies$.response$,
    ]).pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        const timezones = res[0];
        const currencies = res[1];
        if (timezones && currencies) {
          user = {
            ...user,
            timezone: (typeof user.timezone === 'number') ? timezones.filter(tz => tz.id === user.timezone)[0] : user.timezone,
            currency: (typeof user.currency === 'number') ? currencies.filter(c => c.id === user.currency)[0] : user.currency,
          };
          this.form.patchValue(user);
          // In case a SELF_SERVE publisher is changed to be MANAGED we want to make sure all users are set to have CTRL as defaultUi
          if (user.defaultUi === 'SPEARAD' && this.publisherAccountType === 'MANAGED') {
            this.form.get('defaultUi').patchValue('CTRL');
            this.emitFormChanges();
          }
          if (user.type === 'API' && user.gatewayApiKey) {
            this.form.get('gatewayApiKey').enable();
            this.form.get('gatewayApiKey').setValue(user.gatewayApiKey);
            this.form.get('gatewayApiKey').disable();
          }
          // clear password inputs
          this.togglePasswordChange(false);
        }
      });

    // Add entity-specific logic
    if (this.publisher && this.entityType === 'PUBLISHER') {
      this.form.patchValue({
        publisherId: this.publisher.id
      });
    } else if (this.publisher && this.entityType === 'EXCHANGE_PARTNER') {
      this.form.patchValue({
        dealPartnerId: this.publisher.id
      });
    }
  }

  onSubmit() {
    // Don't send password if it is empty, we are not changing it
    if (!this.fc.password.value) {
      this.fc.password.markAsPristine();
    }
    // Don't send password_confirmation if it is empty, we are not changing it
    if (!this.fc.passwordConfirmation.value) {
      this.fc.passwordConfirmation.markAsPristine();
    }
    // Send password if we are sending password_confirmation
    if (this.fc.passwordConfirmation.dirty) {
      this.fc.password.markAsDirty();
    }
    const payload = {
      user: this.fh.getDirtyValues(this.form),
    };
    this.isLoading = true;
    forkJoin([
      this.alertsManagement?.handleAlertsToggle() ?? of({}),
      this.api.updateUserCredential(payload, this.user.id, this.user.type).pipe(
        catchError((error) => {
          if (error.status === 503 || error.status === 400) {
            this.notification.error('Error', error.error.message, { nzDuration: 0 });
          }
          return throwError(error);
        })
      )
    ]).pipe(
      finalize(() => {
        this.isLoading = false;
      }),
      catchError(err => err),
      tap(([_alertsUpdate, userUpdate]) => {
        this.authService.updateMyUser({
          user: userUpdate,
        } as UserWithRoles);
        this.notification.success('Success', 'User updated');
      })
    ).subscribe();
  }

  onCancel() {
    if (this.isPartnerUser) {
      this.router.navigate(['/partner/curated-marketplace-deals']);
    } else {
      this.location.back();
    }
  }

  togglePasswordChange(changeTo?: boolean) {
    if (changeTo !== undefined) {
      this.showChangePassword = changeTo;
    } else {
      this.showChangePassword = !this.showChangePassword;
    }
    if (!this.showChangePassword) {
      this.isPasswordVisible = false;
      this.isConfPasswordVisible = false;
      this.form.get('password').patchValue(null);
      this.form.get('passwordConfirmation').patchValue(null);
      this.form.updateValueAndValidity();
    }
  }

  prepareApiUser() {
    this.form.get('email').disable();
    this.form.get('firstName').disable();
    this.form.get('lastName').disable();
    this.form.get('verified').disable();
    this.form.get('firstName').setValue(this.userInput.firstName);
    this.form.get('lastName').setValue(this.userInput.lastName);
    this.form.get('verified').setValue(true);
    this.emitFormChanges();
  }

  copyToClipboard(key) {
    this.clipboardService.copy(this.form.get(key).value);
    this[`isCopied_${key}`] = true;
    setTimeout(() => {
      this[`isCopied_${key}`] = false;
    }, 1500);
  }

  onChangeSelectIconState() {
    this.isSelectOpen = !this.isSelectOpen;
  }

  generateApiKey() {
    // create API key only for old API users that don't have API key
    // newly created API users generate API key by default in backend
    if (this.userInput?.type === 'API' && this.userInput?.id && !this.userInput?.gatewayApiKey) {
      this.isGeneratingGatewayKey = true;
      const parentType = this.isPartnerUser || this.entityType === 'EXCHANGE_PARTNER' ? 'deal_partner' : 'publisher';
      this.api.generateApiKey(this.userInput.id, parentType).subscribe(res => {
        if (res) {
          this.form.controls.gatewayApiKey.setValue(res.key);
        }
        this.isGeneratingGatewayKey = false;
      },
      () => {
        this.isGeneratingGatewayKey = false;
        this.sharedService.showNotification('error', 'Error occurred',
          'Failed to create an API key for the API user ');
      });
    }
  }

  afterAlertsSubmit(submitResult: { status: boolean; message: string }): void {
    if (submitResult.status) {
      this.notification.success('Success', submitResult.message);
      return;
    }
    this.notification.error('Error', submitResult.message);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
