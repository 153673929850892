import { CtrlUser } from '@/app/shared/_models/models';
import { ApiService } from '@/app/shared/_services/api.service';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { clone, pullAt, sortBy, startCase } from 'lodash';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTabSetComponent } from 'ng-zorro-antd/tabs';
import { forkJoin, of, Subject, throwError } from 'rxjs';
import { catchError, takeUntil, tap, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.less']
})
export class UserManagementComponent implements OnChanges, OnDestroy {


  @ViewChild('usersTabs') usersTabs: NzTabSetComponent;

  @Input() entity: any;
  @Input() entityType: 'PUBLISHER' | 'EXCHANGE_PARTNER';
  @Input() entityForm: UntypedFormGroup;
  users: CtrlUser[] = [];
  entityName: string;
  areUsersLoading = false;
  selectedUserIndex = 0;
  unsubscribe$ = new Subject<void>();
  readonly CURATED_MARKETPLACE_PARTNER_PREFIX = 'partner_';
  readonly NEXXEN_API_DOMAIN = '@NexxenAPI';

  constructor(
    private api: ApiService,
    private notification: NzNotificationService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entity.currentValue) {
      this.getAllUserContacts();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getAllUserContacts() {
    this.users = [];
    this.entityName = this.entityType === 'PUBLISHER' ? 'Publisher' : 'Partner';
    this.selectedUserIndex = 0;
    this.areUsersLoading = true;
    this.api.getUsers(this.generateEntityUsersQuery())
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError(error => {
          this.notification.error('Error', 'Failed to load users');
          return throwError(() => error);
        }),
        finalize(() => this.areUsersLoading = false)
      )
      .subscribe(res => {
        if (res.data && res.data.length) {
          const sorted = sortBy(res.data, [
            (a: CtrlUser) => a.type === 'API' ? 1 : 0,
            (a: CtrlUser) => `${a.firstName.toLowerCase()} ${a.lastName.toLowerCase()}`,
          ]);
          this.users = sorted;
        }
      });
  }

  addUser(type = 'EXTERNAL') {
    const user = { type } as CtrlUser;
    if (type === 'API') {
      const apiEmailPrefix = this.entityType === 'PUBLISHER' ? this.entity?.publisherId : `${this.CURATED_MARKETPLACE_PARTNER_PREFIX}${this.entity?.id}`;
      user.email = `${apiEmailPrefix}${this.NEXXEN_API_DOMAIN}`;
      user.firstName = 'API';
      user.lastName = 'User';
    }
    this.users.push(user);
    this.selectedUserIndex = this.users.length - 1;
  }

  onRemoveUnsavedUser(i) {
    pullAt(this.users, i);
    if (this.usersTabs.nzSelectedIndex === i) {
      this.selectedUserIndex = this.users.length - 1;
    } else if (this.usersTabs.nzSelectedIndex > i) {
      this.selectedUserIndex--;
    }
  }

  toggleUserDeleteStatus(i) {
    this.users[i].toBeRemoved = !this.users[i].toBeRemoved;
  }

  onUserFormChange(event, user: CtrlUser) {
    user.form = event;
  }

  isAnyUserFormInvalid() {
    let isInvalid = false;
    this.users.forEach((user: CtrlUser) => {
      if (user.toBeRemoved !== true) {
        if (user.form && user.form.invalid) {
          isInvalid = true;
        }
      }
    });
    return isInvalid;
  }

  numberOfRemainingUsers() {
    return this.users.filter(user => {
      if (user.id === undefined && user.form === undefined) {
        return;
      }
      if (!user.toBeRemoved) {
        return user;
      }
    }).length;
  }

  executeUserOperations() {
    const add = [];
    const update = [];
    const remove = [];

    // separate users to different actions
    this.users.forEach(user => {
      if (user.toBeRemoved) {
        remove.push(clone(user));
      } else {
        if (user.id && user.form) {
          update.push(clone(user));
        } else if (user.id === undefined && user.form) {
          add.push(clone(user));
        }
      }
    });

    // Convert individual operations to observables
    const addOperations$ = add.map((user: CtrlUser) => {
      const newUserPayload: any = {
        user: {
          ...user.form.getRawValue(),
          type: user.type,
          publisherId: this.entityType === 'PUBLISHER' ? this.entity.publisherId : null,
          dealPartnerId: this.entityType === 'EXCHANGE_PARTNER' ? this.entity.id : null,
        }
      };
      return this.api.createNewUser(newUserPayload);
    });

    const updateOperations$ = update.map((user: CtrlUser) => {
      const updateUserPayload: any = {
        user: {
          ...user.form.value,
        }
      };
      if (updateUserPayload.user.password === null) {
        delete updateUserPayload.user.password;
        delete updateUserPayload.user.passwordConfirmation;
      }
      return this.api.updateUserCredential(updateUserPayload, user.id, 'INTERNAL');
    });

    const removeOperations$ = remove.map((user: CtrlUser) =>
      this.api.deleteUser(user.id)
    );

    return forkJoin([
      addOperations$.length ? forkJoin(addOperations$) : of([]),
      updateOperations$.length ? forkJoin(updateOperations$) : of([]),
      removeOperations$.length ? forkJoin(removeOperations$) : of([])
    ]).pipe(
      tap(() => this.getAllUserContacts()),
      catchError(err => {
        if (err?.error) {
          this.notification.error('Error', err.error.message);
        }
        return throwError(() => err);
      })
    );
  }

  // For publisher component use
  saveAllUserChangesAsPromise(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.executeUserOperations().subscribe({
        next: () => resolve(),
        error: (err) => reject(err)
      });
    });
  }

  // For non-publisher component use
  saveAllUserChanges(): Observable<[any[], any[], any[]]> {
    return this.executeUserOperations();
  }

  toStartCase(str: string) {
    return startCase(str);
  }


  getUserName(user) {
    if (user.type === 'API') {
      return 'API User';
    }
    if (user.id > 0) {
      return`${user.firstName} ${user.lastName}`;
    }
    return 'New User';
  }


  hasApiUser() {
    return this.users.filter(u => u.type === 'API').length ? true : false;
  }



  private generateEntityUsersQuery() {
    const value = this.entityType === 'PUBLISHER' ? this.entity?.publisherId : this.entity?.id;
    const fieldName = this.entityType === 'PUBLISHER' ? 'publisherId' : 'dealPartnerId';
    return {
      'filter': {
        'filters': [ {
          'fieldName': fieldName,
          'operation': 'EQUALS',
          'value': value,
        } ]
      }
    };
  }

}
