import { Component, forwardRef, Input } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { DayPartingValue } from '../../_models/models';
import { FormPartComponent } from '../form-part-component';

@Component({
  selector: 'app-day-parting-accordion',
  templateUrl: './day-parting-accordion.component.html',
  styleUrls: [
    '../app-filters/app-filters.component.less'
  ],
  providers: FormPartComponent.createDirectiveProviders(forwardRef(() => DayPartingAccordionComponent))
})
export class DayPartingAccordionComponent extends FormPartComponent<DayPartingValue[], FormControl> {
  panels = [{ active: false }];
  protected readonly control = new FormControl<DayPartingValue[]>([]);

  constructor() {
    super();
  }

  get hasSelectedDays(): boolean {
    return this.control.value?.some(dp => dp.days?.length > 0) ?? false;
  }

  get selectedDaysCount(): number {
    const dayPartings = this.control.value;

    if (!dayPartings?.length) {
      return 0;
    }

    const uniqueDaysSet = dayPartings.reduce((uniqueDays, schedule) => {
      if (schedule.days) {
        schedule.days.forEach(dayOfWeek => uniqueDays.add(dayOfWeek));
      }
      return uniqueDays;
    }, new Set<number>());

    return uniqueDaysSet.size;
  }

  @Input() set initialExpanded(value: boolean) {
    this.panels = [{ active: value }];
  }

  protected get formPart(): FormControl {
    return this.control;
  }

  protected getFormPartValidationErrors(): ValidationErrors {
    return this.control.errors;
  }
}
