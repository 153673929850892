<ng-container
  *ngIf="domainBundlesForm && ((pubTsId || parentType === 'INVENTORY-PACKAGE') || editMode === 'CREATE' || parentType === 'RX-DEAL' || parentType === 'PAD_AGREEMENT')"
  [formGroup]="domainBundlesForm" (keydown.enter)="$event.preventDefault()">

  <nz-alert
    *ngIf="parentType === 'PAD_AGREEMENT'"
    nzType="info"
    style="margin-bottom: 1.5em"
    nzMessage="If no domains are uploaded, this PAD will apply to all domains.">

  </nz-alert>

  <nz-row nzGutter="32">
    <nz-col nzMd="6" nzSm="24">

      <nz-form-item *ngIf="parentType !== 'RX-DEAL' && parentType !== 'PAD_AGREEMENT'">
        <nz-form-label>Filtering type</nz-form-label>
        <nz-form-control nzErrorTip="Must have at least one domain to be enabled">
          <nz-select nzPlaceHolder="Select filter type" formControlName="filterDomainBundle" nzShowSearch
                     [id]="parentType !== 'TRAFFIC-SOURCE' ? 'filter_domain_bundle' : 'pts_' + pubTsId + '_filter_domain_bundle'"
                     style="width: 100%;"
                     nzShowArrow="false"
                     [nzSuffixIcon]="selectIcons['filtering_type'] ? arrowUp : arrowDown"
                     (nzOpenChange)="onChangeSelectIconState($event, 'filtering_type')">
            <nz-option nzLabel="Disabled" nzValue="DISABLED"></nz-option>
            <nz-option nzLabel="Include" nzValue="WHITELIST"></nz-option>
            <nz-option nzLabel="Exclude" nzValue="BLACKLIST"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <ng-container>
        <nz-form-item>
          <nz-form-label>
            {{ formTitle }}
          </nz-form-label>
          <nz-form-control *ngIf="parent?.type !== 'pubid'" [nzErrorTip]="domainBundlesForm.controls.addDomainBundles.getError('mediaId')">
            <nz-select nzMode="tags" [nzTokenSeparators]="[',', ' ']" style="width: 100%;"
                       nzPlaceHolder="Use space or comma to separate items in list"
                       formControlName="addDomainBundles"
                       nzShowArrow="false"
                       [nzSuffixIcon]="selectIcons['add_domains'] ? arrowUp : arrowDown"
                       [id]="parentType !== 'TRAFFIC-SOURCE' ? 'domain_bundle_add' : 'pts_' + pubTsId + '_domain_bundle_add'"
                       (nzOpenChange)="onChangeSelectIconState($event, 'add_domains')"></nz-select>
          </nz-form-control>
          <nz-form-control *ngIf="parent?.type === 'pubid'">
              <nz-select
                 nzShowSearch
                 nzServerSearch
                 formControlName="addPublishers"
                 nzPlaceHolder="Select publishers"
                 nzMode="multiple"
                 [nzLoading]="true"
                 [nzShowArrow]="false"
                 (nzOnSearch)="searchPublishers($event)">
                <ng-container *ngFor="let option of (listOfPublishers$ | async)">
                  <nz-option *ngIf="!isPublishersLoading" [nzValue]="{id: option.publisherId.toString(), name: option.publisherName}"
                             [nzLabel]="option.publisherName + ' (id: ' + option.publisherId + ')'" [nzDisabled]="option.disabled"></nz-option>
                </ng-container>
                <nz-option *ngIf="isPublishersLoading" nzCustomContent>
                  <span nz-icon class="loading-icon" ></span>
                  Loading publishers...
                </nz-option>
              </nz-select>
            </nz-form-control>

        </nz-form-item>

        <nz-form-item *ngIf="parentType !== 'RX-DEAL' && parentType !== 'PAD_AGREEMENT'">
          <nz-form-control>
            <label nz-checkbox formControlName="includeSubDomains"
                   [id]="parentType !== 'TRAFFIC-SOURCE' ? 'include_subdomains' : 'pts_' + pubTsId + '_include_subdomains'">
              Include subdomains
            </label>
          </nz-form-control>
        </nz-form-item>

        <button nzType="primary"
                type="button"
                nz-button
                nzBlock
                *ngIf="parent?.type !== 'pubid'"
                [id]="getButtonId()"
                [disabled]="!domainBundlesForm.controls.addDomainBundles?.value"
                class="primary-button small-padding small-font domainbundle-add-btn"
                (click)="onDomainBundlesAdd()">
          {{ getButtonText() }}
        </button>
        <button nzType="primary" type="button" nz-button nzBlock
                *ngIf="parent?.type === 'pubid'"
                [id]="parentType !== 'TRAFFIC-SOURCE' ? 'domain_bundle_add_btn' : 'domain_bundle_add_btn_pts'"
                [disabled]="!domainBundlesForm.controls.addPublishers?.value"
                class="primary-button small-padding small-font domainbundle-add-btn"
                (click)="onPublishersAdd()">Add Publishers
        </button>


        <nz-divider></nz-divider>

        <ng-container>
          <nz-form-label>Add by file upload</nz-form-label>
          
          <app-upload-file
            [type]="'drag'"
            [multipleFiles]="false"
            [disabledUplaodButton]="(parentType === 'RX-DEAL') && uploadedFile.length > 0"
            [numberOfFiles]="1"
            [permittedFileTypes]="fileTypes"
            [(fileList)]="uploadedFile"
            [validationFuncs]="[parsingCsvValidation.bind(this)]"
            (fileChange)="onDomainBundleUploadFinished($event)"
            (fileRemove)="handleRemoveFile($event)"
          >
          <p class="ant-upload-drag-icon">
            <em nz-icon nzType="inbox"></em>
          </p>
          <p class="ant-upload-text">Click or drag file to this area to upload</p>
          <p class="ant-upload-hint">
            CSV file with 1 record on each line.
          </p>
        </app-upload-file> 
          
        </ng-container>
      </ng-container>

    </nz-col>

    <nz-col nzMd="18" nzSm="24" class="vertical-left-divider">
      <nz-tabset
        [nzSelectedIndex]="activeTab">
        <nz-tab
          nzTitle="Current State"
          (nzClick)="onTabChanged(0)">
          <ng-template nz-tab>
            <app-filters
              [filters]="domainBundlesFiltersConfig"
              [authPermissions]="authPermissions"
              [buttons]="domainBundlesTableButtons"
              (filtersChanged)="onFiltersChange($event)"
              (buttonClicked)="onButtonClick($event)">
            </app-filters>
            <app-table [table]="domainBundlesTable"
                       [isTableLoading]="isTableLoading"
                       [authPermissions]="authPermissions"
                       [tableColumns]="domainBundlesTableColumns"
                       [tableData]="domainBundles"
                       [totalRows]="domainBundleTotalElements"
                       [isPaginated]="true"
                       [hasSizeChanger]="true"
                       [hasFooter]="true"
                       [idProp]="'id'"
                       (tableSortChanged)="onTableSortChange($event)"
                       (tablePageChanged)="onTablePageChange($event)"
                       (domainBundlesAction)="onRowAction($event)">
            </app-table>
          </ng-template>
        </nz-tab>
        <ng-template #ChangesTitleTemplate>
            <span> Changes To Submit
              <nz-badge  nzStandalone
                         [nzCount]="domainBundleChanges.length"
                         [nzStyle]="{
                          backgroundColor: '#fff',
                          color: '#999',
                          boxShadow: '0 0 0 1px #d9d9d9 inset',
                          transform: 'scale(0.8) translateY(-1px)',
                          marginLeft: '2px'
                        }">
            </nz-badge>
            </span>
        </ng-template>
        <nz-tab [nzTitle]="ChangesTitleTemplate"
                (nzClick)="onTabChanged(1)">
          <ng-template nz-tab>
            <app-filters
              [filters]="domainBundleChangesFiltersConfig"
              [authPermissions]="authPermissions"
              (filtersChanged)="onFiltersChange($event)"
              (buttonClicked)="onButtonClick($event)">
            </app-filters>
            <app-table [table]="domainBundlesChangesTable"
                       [authPermissions]="authPermissions"
                       [tableColumns]="domainBundlesChangesTableColumns"
                       [tableData]="hasSelectionsInFilters() ? domainBundleFilteredChanges : domainBundleChanges"
                       [totalRows]="hasSelectionsInFilters() ? domainBundleFilteredChanges?.length : domainBundleChanges?.length"
                       [isPaginated]="true"
                       [hasSizeChanger]="true"
                       [hasFooter]="false"
                       [tableType]="'clientSide'"
                       [idProp]="'id'"
                       (domainBundlesChangesAction) ="onRowChangesAction($event)">
            </app-table>
          </ng-template>
        </nz-tab>
      </nz-tabset>
    </nz-col>
  </nz-row>

</ng-container>

<ng-template #arrowUp>
  <fa-icon [icon]="'angle-down'" class="select-icon select-icon-open"></fa-icon>
</ng-template>
<ng-template #arrowDown>
  <fa-icon [icon]="'angle-down'" class="select-icon select-icon-close"></fa-icon>
</ng-template>
