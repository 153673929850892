import { ChangeDetectorRef, Component, forwardRef, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { FormPartComponent } from '../form-part-component';
import { DayPartingValue } from '../../_models/models';
import {
  createDefaultDayPartingMatrix,
  fillDayHours,
  updateMatrixHeaders,
  isAllCellsSelected,
  resetMatrix
} from './_utils/matrix.utils';
import {
  convertMinutesToHours,
  normalizeTimeRange,
  formatHourLabel
} from './_utils/time.utils';
import { extractDayPartingFromMatrix } from './_utils/day-parting.utils';
import { DragPoint, DragEndPoint } from './_models/day-parting.models';

@Component({
  selector: 'app-day-parting',
  templateUrl: './day-parting.component.html',
  styleUrls: ['./day-parting.component.less'],
  providers: FormPartComponent.createDirectiveProviders(forwardRef(() => DayPartingComponent))
})
export class DayPartingComponent extends FormPartComponent<DayPartingValue[], FormControl> implements OnInit{
  readonly NUMBER_OF_DAYS: number = 7;
  readonly NUMBER_OF_HOURS: number = 24;
  daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  isDragging = false;
  dragStart: DragPoint | null = null;
  dragEnd: DragEndPoint | null = null;
  hours: string[];
  initialDragState = false;
  selectedDayHeaders: boolean[] = new Array(this.NUMBER_OF_DAYS).fill(false);
  selectedHourHeaders: boolean[] = new Array(this.NUMBER_OF_HOURS).fill(false);
  selectedCells: boolean[][] = createDefaultDayPartingMatrix(this.NUMBER_OF_DAYS, this.NUMBER_OF_HOURS);
  loading$ = new BehaviorSubject<boolean>(false);

  private readonly control = new FormControl();

  constructor(
    private cdref: ChangeDetectorRef,
  ) {
    super();
    this.endDrag = this.endDrag.bind(this);
  }



  writeValue(value: DayPartingValue[] | null): void {
    super.writeValue(value);
    this.selectedCells = createDefaultDayPartingMatrix(this.NUMBER_OF_DAYS, this.NUMBER_OF_HOURS);
    if (value?.length) {
      this.selectedCells = this.createMatrixFromDayPartings(value);
    }
    this.updateHeaderSelections();
  }

  protected get formPart(): FormControl {
    return this.control;
  }

  ngOnInit(): void {
    this.initTable();
  }

  createMatrixFromDayPartings(dayPartings: DayPartingValue[]): boolean[][] {
    const matrix = createDefaultDayPartingMatrix(this.NUMBER_OF_DAYS, this.NUMBER_OF_HOURS);

    dayPartings.forEach(dayParting => {
      if (!dayParting.days?.length) return;

      const startHour = convertMinutesToHours(dayParting.startMinute);
      const endHour = convertMinutesToHours(dayParting.endMinute);
      const { start, end } = normalizeTimeRange(startHour, endHour);

      dayParting.days.forEach(dayKey => {
        fillDayHours(matrix, dayKey, start, end - 1);
      });
    });

    return matrix;
  }

  startDrag(dayIndex: number, hourIndex: number, event: MouseEvent): void {
    event.preventDefault();
    this.isDragging = true;
    this.dragStart = { dayIndex, startHour: hourIndex };
    this.initialDragState = this.selectedCells[dayIndex][hourIndex];
    document.addEventListener('mouseup', this.endDrag);
  }

  continueDrag(dayIndex: number, hour: number): void {
    if (!this.isDragging || !this.dragStart) return;
    this.dragEnd = { dayIndex, endHour: hour };
    const startDayIndex = this.dragStart.dayIndex;
    const endDayIndex = this.dragEnd.dayIndex;
    const startHour = this.dragStart.startHour;
    const endHour = this.dragEnd.endHour;

    for (let d = Math.min(startDayIndex, endDayIndex); d <= Math.max(startDayIndex, endDayIndex); d++) {
      for (let h = Math.min(startHour, endHour); h <= Math.max(startHour, endHour); h++) {
        this.selectedCells[d][h] = !this.initialDragState;
      }
    }
    for (let day = 0; day < this.NUMBER_OF_DAYS; day++) {
      this.selectedDayHeaders[day] = this.selectedCells[day].every(cell => cell);
    }
    for (let hourIndex = 0; hourIndex < this.NUMBER_OF_HOURS; hourIndex++) {
      this.selectedHourHeaders[hourIndex] = this.selectedCells.every(day => day[hourIndex]);
    }

    this.cdref.detectChanges();
  }

  endDrag(): void {
    if (this.isDragging) {
      this.isDragging = false;
      this.dragStart = null;
      this.dragEnd = null;
      this.checkAndResetOrUpdateTimeHeaders();
      this.emitValue();
      this.cdref.detectChanges();
      document.removeEventListener('mouseup', this.endDrag);
    }
  }

  toggleCellSelection(dayIndex: number, hourIndex: number, event?: MouseEvent): void {
    if (!this.isDragging) {
      this.selectedCells[dayIndex][hourIndex] = !this.selectedCells[dayIndex][hourIndex];
      this.checkAndResetOrUpdateTimeHeaders();
      this.emitValue();
      this.cdref.detectChanges();
    }
  }

  isCellSelected(dayIndex: number, hour: number): boolean {
    return this.selectedCells[dayIndex][hour];
  }

  resetSchedule(): void {
    this.selectedCells = this.selectedCells.map(day => day.map(() => false));
    this.selectedHourHeaders.fill(false);
    this.selectedDayHeaders.fill(false);
    this.emitValue();
    this.cdref.detectChanges();
  }

  selectAllInRow(dayIndex: number): void {
    const isRowSelected = this.selectedCells[dayIndex].every(cell => cell);
    this.selectedCells[dayIndex].forEach((_, hourIndex) => {
      this.selectedCells[dayIndex][hourIndex] = !isRowSelected;
    });
    this.checkAndResetOrUpdateTimeHeaders();
    this.emitValue();
    this.cdref.detectChanges();
  }

  selectAllInColumn(hourIndex: number): void {
    const isColumnSelected = this.selectedCells.every(day => day[hourIndex]);
    for (let day = 0; day < this.NUMBER_OF_DAYS; day++) {
      this.selectedCells[day][hourIndex] = !isColumnSelected;
    }
    this.selectedHourHeaders[hourIndex] = !isColumnSelected;
    if (isAllCellsSelected(this.selectedCells)) {
      this.resetSelectedCells();
    } else {
      this.updateHeaderSelections();
    }
    this.emitValue();
    this.cdref.detectChanges();
  }

  protected getFormPartValidationErrors() {
    return this.control.errors;
  }

  private initTable() {
    this.hours = Array.from(
      { length: this.NUMBER_OF_HOURS },
      (_, index) => formatHourLabel(index)
    );
  }

  private resetSelectedCells(): void {
    resetMatrix(this.selectedCells);
    this.selectedDayHeaders.fill(false);
    this.selectedHourHeaders.fill(false);
  }

  private updateHeaderSelections(): void {
    const { dayHeaders, hourHeaders } = updateMatrixHeaders(this.selectedCells);
    this.selectedDayHeaders = dayHeaders;
    this.selectedHourHeaders = hourHeaders;
  }

  private checkAndResetOrUpdateTimeHeaders(): void {
    if (isAllCellsSelected(this.selectedCells)) {
      this.resetSelectedCells();
    } else {
      this.updateHeaderSelections();
    }
  }

  private emitValue(): void {
    const dayPartings = extractDayPartingFromMatrix(this.selectedCells);
    this.control.setValue(dayPartings);
  }

}

