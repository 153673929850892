import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Injectable({ providedIn: 'root' })
export class FileUploadService {

  constructor(private http: HttpClient) {
  }

  uploadFile(url: string, files: NzUploadFile[], otherParams: any = null): Observable<HttpEvent<any>> {

    const formData = new FormData();

    // TODO make it generic when more uploads managed here
    files.forEach((file: any) => {
      formData.append('file', file);
      formData.append('docType', (otherParams?.docType) ? otherParams.docType : 'PAYMENT_TAX');
    });

    const options = {
      reportProgress: true,
    };

    const req = new HttpRequest('POST', url, formData, options);
    return this.http.request(req);

    // return this.http.post<any>(url, formData, {params: params, reportProgress: true});
  }
  uploadMultipleFiles(url: string, files: NzUploadFile[], otherParams: any = null): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const formData = new FormData();
      const options = {
        reportProgress: true,
      };
      const fileuploadsPromises: Promise<any>[] = [];
      // TODO make it generic when more uploads managed here
      files.forEach((file: any) => {
        formData.append('file', file);
        const req = new HttpRequest('POST', url, formData, options);
        fileuploadsPromises.push(this.http.request(req).toPromise());
        formData.delete('file');
      });
      Promise.all(fileuploadsPromises).then(uploadedFiles => {
        if (uploadedFiles.length === files.length) {
          resolve(uploadedFiles);
        }
      })
        .catch((err) => reject(err));
    });
  }

}
