<nz-input-group [nzSuffix]="copyBtnTpl" class="suffix-margin-right-1">
    <nz-select 
        [formControl]="itemsFormControl"
        [nzTokenSeparators]="[',', ' ']"
        class="top-control-no-border"
        style="width: 100%;"
        [id]="selectElementId"
        [nzPlaceHolder]="placeHolder"
        nzMode="tags"
        nzShowArrow="false"
        nzAllowClear
        nzShowSearch
        [nzMaxTagCount]="maxTagCount">
    </nz-select>
  </nz-input-group>
  <ng-template #copyBtnTpl>
    <div class="display-flex align-items-center">
        <span class="smaller-font bold-font font-icon-color-darker margin-right-small background-white"
              *ngIf="isCopied_items" @fadeOut>Copied to clipboard</span>
      <fa-icon icon="copy"
            *ngIf="itemsFormControl.value && itemsFormControl.value.length"
            (click)="clipboardCopy(itemsFormControl.value, true)"
            class="font-icon-color-darker cursor-pointer background-white margin-right-small"
            id="copyToClipboardBtn-apReviewAppSites"></fa-icon>
    </div>
  </ng-template>