import { DealPartner, DmpAudienceProvider, PlatformFeeEnum } from '@/app/shared/_models/models';
import { SharedStoreService } from '@/app/shared/_services/shared-store.service';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { map, take } from 'rxjs/operators';
export type PartnerInfoMode = 'create' | 'edit' | 'disabled';

@Component({
  selector: 'app-deal-partner-info',
  templateUrl: './deal-partner-info.component.html',
  styleUrls: ['./deal-partner-info.component.less']
})
export class DealPartnerInfoComponent implements OnInit, OnChanges {
  @Input() mode: PartnerInfoMode = 'create';
  @Input() partner: DealPartner;
  @Input() dmpAudienceProviders: DmpAudienceProvider[] = [];
  @Input() form: FormGroup;
  @Output() statusChange = new EventEmitter<boolean>();
  @Output() delete = new EventEmitter<void>();

  platformFeeOptions: { value: string; displayName: string }[] = [];

  availableAudienceProviders: DmpAudienceProvider[] = [];

  constructor(
    private sharedStoreService: SharedStoreService
  ) {}

  ngOnInit() {
    this.initializePlatformFeeType();
    this.initializeAudienceProviders();
    this.initDisabledMode();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.partner && changes.partner.currentValue && this.form) {
      if (this.partner?.platformFeeType) {
        const matchingOption = this.platformFeeOptions.find(
          option => option.value.toLowerCase() === this.partner.platformFeeType.toLowerCase()
        );

        if (matchingOption) {
          this.form.get('platformFeeType').setValue(matchingOption.value);
        }
      }
    }
  }
  private initDisabledMode(){
    if (this.mode === 'disabled') {
      if (this.partner?.platformFeeType) {
        const matchingOption = this.platformFeeOptions.find(
          option => option.value.toLowerCase() === this.partner.platformFeeType.toLowerCase()
        );

        if (matchingOption) {
          this.form.get('platformFeeType').setValue(matchingOption.value);
        }
      }
      this.form.disable();
    }
  }

  private initializeAudienceProviders() {
    const uniqueProviderIds = new Set<number>();

    this.availableAudienceProviders = [...this.dmpAudienceProviders];
    this.dmpAudienceProviders.forEach(provider => {
      uniqueProviderIds.add(provider.providerId);
    });

    if (this.mode !== 'create' && this.partner?.audienceProviders) {
      this.partner.audienceProviders.forEach(provider => {
        if (!uniqueProviderIds.has(provider.providerId)) {
          this.availableAudienceProviders.push(provider);
          uniqueProviderIds.add(provider.providerId);
        }
      });
    }
  }

  private initializePlatformFeeType() {
    this.sharedStoreService.getSharedEnum('PartnerPlatformFeeType').pipe(
      take(1),
      map(response => Object.entries(response).map(([value, displayName]) => ({
        value: value,
        displayName: displayName
      })))
    ).subscribe(platformFeeTypes => {
      this.platformFeeOptions = platformFeeTypes;
      if (this.partner?.platformFeeType) {
        this.form.get('platformFeeType').setValue(this.partner.platformFeeType.toLowerCase());
      }
    });
  }
}
