import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { GeoTargetingService } from './_services/geo-targeting.service';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import {
  Country,
  CountryToRegionsMap,
  JapiQuery,
  Metro,
  ResponseFromServer,
  State,
} from '../../_models/models';
import { SharedStoreService } from '../../_services/shared-store.service';
import _, { isEqual } from 'lodash';
import { createEmptyClusivityList } from '../../_models/clusivity-list';
import { ClusivityListSelectOption } from '../clusivity-list/clusivity-list-select-options/clusivity-list-select-option.model';
import { FormPartComponent } from '../form-part-component';
import { forbiddenCharValidator } from './geo-targeting.validations';
import { TreeNode } from '../clusivity-list/clusivity-list-select-options-tree/clusivity-list-select-options-tree.models';
import { FilterOptions, GeoTargetingForm } from './geo-targeting.models';

@Component({
  selector: 'app-geo-targeting',
  templateUrl: './geo-targeting.component.html',
  styleUrls: ['./geo-targeting.component.less'],
  providers: FormPartComponent.createDirectiveProviders(GeoTargetingComponent)
})

export class GeoTargetingComponent extends FormPartComponent<GeoTargetingForm, FormGroup> implements OnInit {
  formPart: FormGroup;
  allCountries: Country[] = [];
  transformedCountries: ClusivityListSelectOption<string>[] = [];
  regionsTree: TreeNode[] = [];
  transformedMetros: ClusivityListSelectOption<string>[] = [];
  countryWithMetrosAlpha3 = 'usa';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private geoTargetingService: GeoTargetingService,
    private sharedStoreService: SharedStoreService,
  ) {
    super();
  }

  protected get zipCodesErrorMessage(): string | null {
    const hasError = this.formPart?.hasError('specialCharsErrorMsg');
    if (!hasError) return null;
    return 'Zip code cannot contain special characters';
  }

  getFormPartValidationErrors() {
    return this.formPart.errors;
  }

  override registerOnChange(onChangeCallback: (newGeoTargeting: GeoTargetingForm) => void): void {
    super.registerOnChange(newGeoTargeting => {
      const expandedGeoTargeting = this.expandRegionsFromCountries(newGeoTargeting);
      onChangeCallback(expandedGeoTargeting);
    });
  }

  expandRegionsFromCountries(newGeoTargeting: GeoTargetingForm): GeoTargetingForm {
    const regions = newGeoTargeting.regionsClusiveList?.items;
    if (!regions || !regions.length) {
      return newGeoTargeting;
    }

    const countriesInRegionsField = this.filterCountriesInRegions(regions);
    if (!countriesInRegionsField.length) {
      return newGeoTargeting;
    }

    const expandedRegions = this.getExpandedRegions(countriesInRegionsField, regions);
    this.updateRegionsClusiveList(expandedRegions);
    return newGeoTargeting;
  }

  initForm(): void {
    this.formPart = this.formBuilder.group({
      countriesClusiveList: [createEmptyClusivityList<string>()],
      metrosClusiveList: [createEmptyClusivityList<string>()],
      zipCodesClusiveList: [createEmptyClusivityList<string>()],
      regionsClusiveList: [createEmptyClusivityList<string>()]
    }, { validators: [forbiddenCharValidator] });
  }

  ngOnInit() {
    this.initForm();
    this.fetchCountries();
    this.subscribeFormChanges();
  }



  generateRegionsTree(regions: CountryToRegionsMap): TreeNode[] {
    const regionsTree: TreeNode[] = [];

    Object.entries(regions).forEach(([title, regions]) => {
      const parentNode = this.createParentNode(title, regions);
      regionsTree.push(parentNode);
    });

    this.regionsTree = regionsTree;
    this.updateRegionsBasedOnSelectedCountries();
    return regionsTree;
  }

  handleCountriesSelected(queryProp: string[]): void {
    const alpha3Countries = this.convertAlpha2ToAlpha3(queryProp);
    const shouldAllowChoosingMetros = alpha3Countries.includes(this.countryWithMetrosAlpha3);
    if (shouldAllowChoosingMetros) {
      this.fetchRegionsAndMetros(alpha3Countries, this.countryWithMetrosAlpha3).subscribe();
    } else {
      this.fetchRegionsOnly(alpha3Countries).subscribe();
    }
  }

  subscribeFormChanges(): void {
    const geoTargetingChangesStream = this.createChangesStream().pipe(
      map(geoTargeting => geoTargeting.countriesClusiveList),
      filter(countriesClusiveList => countriesClusiveList != null),
      distinctUntilChanged((first, second) => isEqual(first, second))
    );
    const subscription = geoTargetingChangesStream.subscribe(({ items }: { items: string[] }) => {
      this.processSelectedCountries(items);
    });
    this.subscriptions.add(subscription);
  }



  getRegions(selectedCountries: (string | number)[]): Observable<ResponseFromServer<CountryToRegionsMap>> {
    const query = {
      filter: {
        filters: [{
          fieldName: 'country', operation: 'IN', value: selectedCountries
        }]
      }
    } as JapiQuery;
    return this.geoTargetingService.getCountryToRegions(query)
      .pipe(tap((regionsRes) => {
        this.generateRegionsTree(regionsRes.data);
      }));
  }

  getMetros(selectedCountry: string | number): Observable<ResponseFromServer<Metro[]>> {
    const query = {
      filter: {
        filters: [{
          fieldName: 'country', operation: 'IN', value: selectedCountry
        }]
      }
    } as JapiQuery;
    return this.geoTargetingService.getMetros(query)
      .pipe(tap((metrosRes) => {
        if (metrosRes?.data?.length > 0) {
          this.transformedMetros = metrosRes?.data.map(metro => ({ item: `${metro.metroKey}`, displayName: metro.displayMetroName }));
        }
      }));
  }

  private disableFilterLists({
    region = true,
    dma = true,
    zipCode = true
  }: FilterOptions = {}): void {
    if (region) {
      this.formPart.get('regionsClusiveList')?.disable();
    }
    if (dma) {
      this.formPart.get('metrosClusiveList')?.disable();
    }
    if (zipCode) {
      this.formPart.get('zipCodesClusiveList')?.disable();
    }
  }

  private enableFilterLists({
    region = true,
    dma = true,
    zipCode = true
  }: FilterOptions = {}): void {
    if (region) {
      this.formPart.get('regionsClusiveList')?.enable();
    }
    if (dma) {
      this.formPart.get('metrosClusiveList')?.enable();
    }
    if (zipCode) {
      this.formPart.get('zipCodesClusiveList')?.enable();
    }
  }

  private resetFilterListsValue({
    region = true,
    dma = true,
    zipCode = true
  }: FilterOptions = {}): void {
    if (region) {
      this.formPart.get('regionsClusiveList')?.setValue({ clusivity: null, items: null });
    }
    if (dma) {
      this.formPart.get('metrosClusiveList')?.setValue({ clusivity: null, items: null });
    }
    if (zipCode) {
      this.formPart.get('zipCodesClusiveList')?.setValue({ clusivity: null, items: null });
    }
  }

  private capitalizeWords(str: string): string {
    return str.replace(/\b\w/g, char => char.toUpperCase());
  }

  private fetchRegionsAndMetros(
    countries: string[],
    metroCountryCode: string
  ): Observable<[ResponseFromServer<CountryToRegionsMap>, ResponseFromServer<Metro[]>]> {
    return forkJoin([
      this.getRegions(countries),
      this.getMetros(metroCountryCode)
    ]).pipe(
      tap(() => {
        this.enableFilterLists();
      }),
    );
  }

  private fetchRegionsOnly(countries): Observable<ResponseFromServer<CountryToRegionsMap>> {
    return this.getRegions(countries).pipe(
      tap(() => {
        this.disableFilterLists({ region: false, dma: true, zipCode: false });
        this.enableFilterLists({ region: true, dma: false, zipCode: true });
        this.resetFilterListsValue({ region: false, dma: true, zipCode: false });
      }),
    );
  }

  private handleNoCountriesSelected(): void {
    this.resetFilterListsValue();
    this.disableFilterLists();
  }

  private processSelectedCountries(items: string[]): void {
    if (!items || items.length === 0) {
      this.handleNoCountriesSelected();
    } else {
      this.handleCountriesSelected(items);
    }
  }

  private updateRegionsBasedOnSelectedCountries(): void {
    const regionsClusiveList = this.formPart.get('regionsClusiveList');
    const currentRegions = regionsClusiveList?.value?.items || [];
    const validRegionKeys = this.getValidRegionKeys();
    const regionsToKeep = this.filterValidRegions(currentRegions, validRegionKeys);
    const clusivity = regionsClusiveList.value.clusivity;
    regionsClusiveList.setValue({
      clusivity,
      items: regionsToKeep
    });
  }

  private getValidRegionKeys(): string[] {
    return [
      ...this.regionsTree.map(region => region.key),
      ...this.regionsTree.flatMap(region => region.children.map(child => child.key))
    ];
  }

  private filterValidRegions(currentRegions: string[], validRegionKeys: string[]): string[] {
    return currentRegions.filter(region => validRegionKeys.includes(region));
  }

  private convertAlpha2ToAlpha3(alpha2Countries: string[]): string[] {
    return alpha2Countries.map(alpha2 => this.allCountries.find(country => country.alpha2 === alpha2)?.alpha3 || '');
  }

  private filterCountriesInRegions(regions: string[]): string[] {
    return regions.filter(region => region && /^[a-zA-Z]+$/.test(region));
  }

  private getExpandedRegions(isCountriesInRegionsField: string[], regions: string[]): string[] {
    let expandedRegions = regions.filter(region => !isCountriesInRegionsField.includes(region));
    for (const country of isCountriesInRegionsField) {
      const countryTreeNode = this.regionsTree.find(node => node.key === country);
      if (countryTreeNode) {
        const countryRegions = countryTreeNode.children.map(child => child.key);
        expandedRegions = [...expandedRegions, ...countryRegions];
      }
    }
    return expandedRegions;
  }

  private updateRegionsClusiveList(expandedRegions: string[]): void {
    this.formPart.get('regionsClusiveList').setValue({
      clusivity: this.formPart.get('regionsClusiveList').value.clusivity,
      items: expandedRegions
    });
  }

  private fetchCountries(): void {
    const countriesStream = this.sharedStoreService.getSharedAsset('countries');
    const countriesSubscription = countriesStream.subscribe((countries) => {
      this.handleCountriesChange(countries);
    });
    this.subscriptions.add(countriesSubscription);
  }

  private handleCountriesChange(countries: ResponseFromServer<Country[]>) {
    this.allCountries = countries.data;
    this.transformedCountries = this.allCountries.map(country => ({ item: country.alpha2, displayName: country.name }));
  }

  private createParentNode(title: string, states: State[]): TreeNode {
    const foundCountry = this.allCountries.find(c => c.alpha3 === title);
    return {
      key: title,
      title: foundCountry ? foundCountry.name : this.capitalizeWords(title),
      children: this.createChildrenNodes(states),
      checked: false
    };
  }

  private createChildrenNodes(states: State[]): TreeNode[] {
    return states.map(state => ({
      key: state.regionKey.toString(),
      title: this.capitalizeWords(state.displayRegion),
      isLeaf: true,
      checked: false
    }));
  }
}
