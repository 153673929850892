<div class="day-parting-container">
    <button nz-button class="secondary-button width-fit-content align-self-end small-font small-height small-padding margin-bottom-1" (click)="resetSchedule()">
      Reset Schedule
    </button>
  <div class="day-parting-schedule-overflow">
    <div class="day-parting-schedule">
      <!-- Hours header -->
      <div></div>
      <div *ngFor="let hour of hours; let i = index" 
           class="hour-label"
           [class.selected]="selectedHourHeaders[i]"
           (click)="selectAllInColumn(i)">
        {{ hour }}
      </div>
      <div class="row-separator"></div>

      <!-- Day rows with hours -->
      <ng-container *ngFor="let day of daysOfWeek; let dayIndex = index">
        <div class="day-name"
             [class.selected]="selectedDayHeaders[dayIndex]"
             (click)="selectAllInRow(dayIndex)">{{ day }}</div>
        <div *ngFor="let hour of hours; let hourIndex = index"
             class="hour-cell"
             [class.selected]="isCellSelected(dayIndex, hourIndex)"
             (mousedown)="startDrag(dayIndex, hourIndex, $event)"
             (mouseenter)="continueDrag(dayIndex, hourIndex)"
             (mouseup)="endDrag()"
             (click)="toggleCellSelection(dayIndex, hourIndex, $event)">
        </div>
        <div class="row-separator"></div>
      </ng-container>
    </div>
  </div>

</div>



