import { DayPartingValue } from '../../../_models/models';
import { TimeRange, DayTimeRanges } from '../_models/day-parting.models';
import { convertHoursToMinutes } from './time.utils';

export function extractDayPartingFromMatrix(matrix: boolean[][]): DayPartingValue[] {
  const timeRangesByDay = matrix.map((hours, dayIndex) => ({
    dayIndex,
    ranges: findTimeRangesInDay(hours)
  }));

  return groupRangesByTime(timeRangesByDay);
}

function findTimeRangesInDay(hours: boolean[]): TimeRange[] {
  const ranges: TimeRange[] = [];
  let currentStart: number | null = null;

  hours.forEach((selected, hourIndex) => {
    if (selected) {
      currentStart = currentStart ?? hourIndex;
    }
    if ((!selected || hourIndex === hours.length - 1) && currentStart !== null) {
      const endHour = (selected && hourIndex === hours.length - 1) ? hourIndex : hourIndex - 1;
      ranges.push({
        start: convertHoursToMinutes(currentStart),
        end: convertHoursToMinutes(endHour + 1)
      });
      currentStart = null;
    }
  });

  return ranges;
}

function groupRangesByTime(timeRangesByDay: DayTimeRanges[]): DayPartingValue[] {
  const groupedRanges: DayPartingValue[] = [];

  timeRangesByDay.forEach(({ dayIndex, ranges }) => {
    ranges.forEach(range => {
      const existingRange = groupedRanges.find(gr =>
        gr.startMinute === range.start &&
                gr.endMinute === range.end
      );

      if (existingRange) {
        existingRange.days.push(dayIndex);
      } else {
        groupedRanges.push({
          days: [dayIndex],
          startMinute: range.start,
          endMinute: range.end
        });
      }
    });
  });

  return groupedRanges;
}
