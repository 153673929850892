export function createDefaultDayPartingMatrix(days: number, hours: number): boolean[][] {
  return Array.from({ length: days }, () =>
    Array.from({ length: hours }, () => false)
  );
}

export function fillDayHours(matrix: boolean[][], dayKey: number, startHour: number, endHour: number): void {
  for (let hour = startHour; hour <= endHour; hour++) {
    matrix[dayKey][hour] = true;
  }
}

export function updateMatrixHeaders(matrix: boolean[][]): {
  dayHeaders: boolean[];
  hourHeaders: boolean[];
} {
  const hours = matrix[0].length;

  const dayHeaders = matrix.map(day => day.every(cell => cell));
  const hourHeaders = Array(hours).fill(false).map((_, hour) =>
    matrix.every(day => day[hour])
  );

  return { dayHeaders, hourHeaders };
}

export function isAllCellsSelected(matrix: boolean[][]): boolean {
  return matrix.every(row => row.every(cell => cell));
}

export function resetMatrix(matrix: boolean[][]): void {
  matrix.forEach(row => row.fill(false));
}
