import { NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faAngleDown,
  faAngleUp,
  faArrowAltCircleDown,
  faArrowAltCircleLeft,
  faArrowCircleLeft,
  faArrowLeft,
  faBan,
  faBell,
  faBoxOpen,
  faBriefcase,
  faBullhorn,
  faCalendarAlt,
  faCaretDown,
  faCaretUp,
  faChartArea,
  faChartLine,
  faChartPie,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleNotch,
  faClipboardList,
  faCog,
  faCogs,
  faCopy,
  faCreditCard,
  faDollarSign,
  faEdit,
  faEquals,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileContract,
  faFileExcel,
  faFileInvoiceDollar,
  faFileUpload,
  faFilter,
  faGripHorizontal,
  faHistory,
  faHome,
  faInfo,
  faInfoCircle,
  faKey,
  faLayerGroup,
  faLink,
  faList,
  faListUl,
  faMinusCircle,
  faPause,
  faPlay,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faRedoAlt,
  faSave,
  faSearch,
  faSearchPlus,
  faShieldAlt,
  faSignOutAlt,
  faSlidersH,
  faSortAmountDown,
  faSortAmountUp,
  faSpinner,
  faSyncAlt,
  faTachometerAlt,
  faTag,
  faTable,
  faTasks,
  faTimes,
  faTimesCircle,
  faTrash,
  faUnlockAlt,
  faUpload,
  faUser,
  faUserCheck,
  faUserCircle,
  faUserCog,
  faUserMd,
  faUsers,
  faUsersCog,
  faSearchMinus,
  faFighterJet
} from '@fortawesome/free-solid-svg-icons';
import {
  faHandshake as farHandshake,
  faHdd as farHdd,
  faEnvelope as farEnvelope,
  faCheckCircle as farCheckCircle,
  faTimesCircle as farTimesCircle,
} from '@fortawesome/free-regular-svg-icons';

import { Common } from './_common/common.helper';
import { FormHelper } from './_common/form.helper';
import { PipeManager } from './_common/pipeManager.helper';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './_interceptors/jwt.interceptor';
import { ErrorInterceptor } from './_interceptors/error.interceptor';
import { VersionInterceptor } from './_interceptors/version.interceptor';
import { FilenameInterceptor } from './_interceptors/filename.interceptor';

import { TruncatePipe } from './_pipes/truncate.pipe';
import { MapFromKeyValuePipe } from './_pipes/mapFromKeyValue.pipe';
import { WidgetFormatPipe } from './_pipes/widgetFormat.pipe';
import { SimplePercentPipe } from './_pipes/simple_percent.pipe';
import { StripHtmlPipe } from './_pipes/stripHtml.pipe';
import { LogPipe } from './_pipes/log.pipe';

import { SvgUnrulyLogo } from './_components/svg/unruly-logo.component';
import { SvgAngular } from './_components/svg/angular.component';
import { AppTableComponent } from './_components/app-table/app-table.component';
import { ClickOutsideDirective } from './_directives/click-outside.directive';
import { BillingHistoryComponent } from './_components/billing-history/billing-history.component';
import { FocusInvalidDirective } from './_directives/focus-invalid.directive';
import { SharedTaxInfoComponent } from './_components/tax-info/tax-info.component';
import { SharedPaymentInfoComponent } from './_components/payment-info/payment-info.component';
import { SharedKpiWidgetComponent } from './_components/kpi-widget/kpi-widget.component';
import { AppChartComponent } from './_components/app-chart/app-chart.component';
import { SharedGlobalSearchComponent } from './_components/global-search/global-search.component';

import { SanitizerPipe } from './_pipes/sanitizer.pipe';
import { PlacementsRequiredComponent } from './_components/placements-required/placements-required.component';
import { FormatTextPipe } from './_pipes/format-text.pipe';
import { AppFiltersComponent } from './_components/app-filters/app-filters.component';
import { AppInputComponent } from './_components/app-input/app-input.component';
import { RouterModule } from '@angular/router';
import { EnumsPipe } from './_pipes/enums.pipe';
import { SignupInfoComponent } from './_components/signup-info/signup-info.component';
import { UserManagementUpdateComponent } from './_components/user-management-update/user-management-update.component';
import { PlacementsComponent } from '../features/placements/placements.component';
import { PlacementEditComponent } from '../features/placements/edit-placement/edit-placement.component';
import { PlacementFloorsComponent } from '../features/placements/edit-placement/placement-floors/placement-floors.component';
import { PlacementCommissionsComponent } from '../features/placements/edit-placement/placement-commissions/placement-commissions.component';
import { BlockedListsComponent } from '../features/placements/edit-placement/blocked-lists/blocked-lists.component';
import { DomainBundlesComponent } from './_components/domain-bundles/domain-bundles.component';
import { SYSTEM_ZORRO_ICONS } from './_common/icons';
import { FeatureToggleDirective } from './_directives/feature-toggle.directive';
import { AppZorroModule } from '../app-zorro.module';
import { ExcludeSelectOptionPipe } from './_pipes/exclude-select-option.pipe';
import { EllipsisAndTooltipDirective } from './_directives/ellipsis-and-tooltip.directive';
import { LottieModule } from 'ngx-lottie';
import { OnScrollToElementDirective } from './_directives/on-scroll-to-element.directive';
import { SortByPipe } from './_pipes/sort-by.pipe';
import { PublisherSellersComponent } from './_components/publisher-sellers/publisher-sellers.component';
import { GtmIdImplementDirective } from './_directives/gtm-id-implement.directive';
import { NotFoundComponent } from './error/notfound/notfound.component';
import { ForbiddenComponent } from './error/forbidden/forbidden.component';
import { FixedTableHeaderDirective } from './_directives/fixed-table-header.directive';
import { AppModalComponent } from './_components/app-modal/app-modal.component';
import { BlockedAdvertisersComponent } from './_components/blocked-advertisers/blocked-advertisers.component';
import { PasswordValidatorComponent } from './_components/password-validator/password-validator.component';
import { UserFormPasswordComponent } from './_components/user-form-password/user-form-password.component';
import { UploadContractComponent } from './_components/upload-contract/upload-contract.component';
import { AuditComponent } from './_components/audit/audit.component';
import { BillPaymentInfoComponent } from './_components/billing-history/bill-payment-info/bill-payment-info.component';
import { CustomComponentHostDirective } from './_directives/custom-component-host.directive';
import { WarningBoxComponent } from './_components/warning-box/warning-box.component';
import { NzMaxTagsByLengthDirective } from './_directives/nz-max-tags-by-length.directive';
import {
  CommissionFixedCpmWarningTextComponent
} from './_components/commission-fixed-cpm-warning-text/commission-fixed-cpm-warning-text.component';
import { AppDropdownButtonComponent } from './_components/app-dropdown-button/app-dropdown-button.component';
import {
  AlertsManagementComponent
} from './_components/alerts-management/alerts-management.component';
import { EditAlertComponent } from './_components/alerts-management/edit-alert/edit-alert.component';
import {
  AddPublisherToAlertComponent
} from './_components/alerts-management/edit-alert/add-publisher-to-alert/add-publisher-to-alert.component';
import { AppIcon } from './_components/svg/app-icon.component';
import { ContentControlsComponent } from './_components/content-controls/content-controls.component';
import { GeoTargetingComponent } from './_components/geo-targeting/geo-targeting.component';
import { AudienceTargetingComponent } from './_components/audience-targeting/audience-targeting.component';
import { AudienceTargetingSegmentComponent } from './_components/audience-targeting-segment/audience-targeting-segment.component';
import { ShowIfTextLeakedDirective } from './_directives/show-if-text-leaked.directive';
import { DealExternalSyncComponent } from './_components/deal-external-sync/deal-external-sync.component';
import {SentinelApiInterceptor} from './_interceptors/sentinel-api.interceptor';
import { IsLoadingPipe } from './_pipes/is-loading.pipe';
import { MaintenanceComponent } from './error/maintenance/maintenance.component';
import { UploadFileComponent } from './_components/upload-file/upload-file.component';
import { UploadLogoComponent } from './_components/upload-logo/upload-logo.component';
import { ClusivityListSelectOptionsComponent } from './_components/clusivity-list/clusivity-list-select-options/clusivity-list-select-options.component';
import { ClusivityListComponent } from './_components/clusivity-list/clusivity-list.component';
import { ClusivityListSelectOptionGroupsComponent } from './_components/clusivity-list/clusivity-list-select-option-groups/clusivity-list-select-option-groups.component';
import { ClusivityListMultiTextInputComponent } from './_components/clusivity-list/clusivity-list-multi-text-input/clusivity-list-multi-text-input.component';
import { ClusivityListSelectOptionsTreeComponent } from './_components/clusivity-list/clusivity-list-select-options-tree/clusivity-list-select-options-tree.component';
import { UserManagementComponent } from './_components/user-management/user-management/user-management.component';
import { DealPartnerInfoComponent } from './_components/deal-partner-info/deal-partner-info.component';
import { DayPartingComponent } from './_components/day-parting/day-parting.component';
import { DayPartingAccordionComponent } from './_components/day-parting-accordion/day-parting-accordion.component';

const playerFactory = () => import('lottie-web');

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxChartsModule,
    FontAwesomeModule,
    RouterModule,
    ScrollingModule,
    AppZorroModule,
    LottieModule.forRoot({player: playerFactory})
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AppZorroModule,
    NgxChartsModule,
    FontAwesomeModule,
    ScrollingModule,
    CKEditorModule,
    TruncatePipe,
    MapFromKeyValuePipe,
    WidgetFormatPipe,
    SimplePercentPipe,
    StripHtmlPipe,
    AppTableComponent,
    AppFiltersComponent,
    LogPipe,
    SortByPipe,
    IsLoadingPipe,
    // Assets
    AppIcon,
    SvgUnrulyLogo,
    SvgAngular,
    BillingHistoryComponent,
    FocusInvalidDirective,
    SharedTaxInfoComponent,
    SharedPaymentInfoComponent,
    SharedKpiWidgetComponent,
    AppChartComponent,
    // AppBarChartComponent,
    // MultiSelectionDropdownComponent,
    ClickOutsideDirective,
    PlacementsRequiredComponent,
    SharedGlobalSearchComponent,
    SignupInfoComponent,
    UserManagementUpdateComponent,
    PlacementsComponent,
    PlacementEditComponent,
    PlacementFloorsComponent,
    DomainBundlesComponent,
    FeatureToggleDirective,
    ExcludeSelectOptionPipe,
    EllipsisAndTooltipDirective,
    FormatTextPipe,
    OnScrollToElementDirective,
    PublisherSellersComponent,
    SanitizerPipe,
    GtmIdImplementDirective,
    NotFoundComponent,
    ForbiddenComponent,
    FixedTableHeaderDirective,
    AppModalComponent,
    PasswordValidatorComponent,
    UserFormPasswordComponent,
    AuditComponent,
    UploadContractComponent,
    CustomComponentHostDirective,
    WarningBoxComponent,
    NzMaxTagsByLengthDirective,
    AppDropdownButtonComponent,
    CommissionFixedCpmWarningTextComponent,
    ContentControlsComponent,
    GeoTargetingComponent,
    AudienceTargetingComponent,
    AudienceTargetingSegmentComponent,
    ShowIfTextLeakedDirective,
    DealExternalSyncComponent,
    MaintenanceComponent,
    UploadFileComponent,
    UploadLogoComponent,
    ClusivityListSelectOptionsComponent,
    ClusivityListComponent,
    ClusivityListSelectOptionGroupsComponent,
    ClusivityListSelectOptionsTreeComponent,
    ClusivityListMultiTextInputComponent,
    UserManagementComponent,
    DealPartnerInfoComponent,
    DayPartingComponent,
    DayPartingAccordionComponent
  ],
  declarations: [
    TruncatePipe,
    MapFromKeyValuePipe,
    WidgetFormatPipe,
    SimplePercentPipe,
    StripHtmlPipe,
    AppTableComponent,
    ClickOutsideDirective,
    LogPipe,
    // Assets
    AppIcon,
    SvgUnrulyLogo,
    SvgAngular,
    BillingHistoryComponent,
    FocusInvalidDirective,
    SharedTaxInfoComponent,
    SharedPaymentInfoComponent,
    SharedKpiWidgetComponent,
    AppChartComponent,
    // AppBarChartComponent,
    SanitizerPipe,
    IsLoadingPipe,
    PlacementsRequiredComponent,
    SharedGlobalSearchComponent,
    FormatTextPipe,
    AppFiltersComponent,
    AppInputComponent,
    EnumsPipe,
    SignupInfoComponent,
    UserManagementUpdateComponent,
    PlacementsComponent,
    PlacementEditComponent,
    PlacementFloorsComponent,
    PlacementCommissionsComponent,
    BlockedListsComponent,
    DomainBundlesComponent,
    FeatureToggleDirective,
    ExcludeSelectOptionPipe,
    EllipsisAndTooltipDirective,
    OnScrollToElementDirective,
    SortByPipe,
    PublisherSellersComponent,
    GtmIdImplementDirective,
    NotFoundComponent,
    ForbiddenComponent,
    FixedTableHeaderDirective,
    AppModalComponent,
    BlockedAdvertisersComponent,
    PasswordValidatorComponent,
    UserFormPasswordComponent,
    UploadContractComponent,
    AuditComponent,
    BillPaymentInfoComponent,
    CustomComponentHostDirective,
    WarningBoxComponent,
    NzMaxTagsByLengthDirective,
    AppDropdownButtonComponent,
    CommissionFixedCpmWarningTextComponent,
    GeoTargetingComponent,
    AlertsManagementComponent,
    EditAlertComponent,
    AddPublisherToAlertComponent,
    ContentControlsComponent,
    AudienceTargetingComponent,
    AudienceTargetingSegmentComponent,
    ShowIfTextLeakedDirective,
    DealExternalSyncComponent,
    MaintenanceComponent,
    UploadFileComponent,
    UploadLogoComponent,
    ClusivityListSelectOptionsComponent,
    ClusivityListComponent,
    ClusivityListSelectOptionGroupsComponent,
    ClusivityListSelectOptionsTreeComponent,
    ClusivityListMultiTextInputComponent,
    UserManagementComponent,
    DealPartnerInfoComponent,
    DayPartingComponent,
    DayPartingAccordionComponent
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: SentinelApiInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: FilenameInterceptor, multi: true},
    {provide: NZ_ICONS, useValue: SYSTEM_ZORRO_ICONS},
    {provide: NZ_I18N, useValue: en_US},
    Common, FormHelper, PipeManager,
    Title
  ]
})

export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faFileExcel, faRedoAlt, faAngleDown, faCalendarAlt, faAngleUp, faPlus, faSearch, faSearchPlus, faSearchMinus,
      faFilter, faInfo, faTachometerAlt, faArrowLeft, faEdit, faSave, faCopy, faCheck, faQuestionCircle, faUsers, faUser, faSlidersH,
      faExclamationCircle, faTimesCircle, faBriefcase, faUserCircle, faUserMd, faFileInvoiceDollar, faCreditCard, faUpload, faSignOutAlt,
      faDollarSign, faBan, faPlusCircle, faTimes, faCogs, faCog, faBullhorn, faUsersCog, faUnlockAlt, faHistory, faFileAlt,
      faMinusCircle, faShieldAlt, faInfoCircle, faUserCog, faList, faFileUpload, faTrash, faListUl, faUserCheck, faFileContract, faKey,
      faSyncAlt, faCheckCircle, faChevronDown, faChevronUp, faChevronLeft, faChevronRight, faEye, faEyeSlash, faBoxOpen, faSortAmountDown,
      faSortAmountUp, faEquals, faChartArea, faClipboardList, faLink, faArrowCircleLeft, faChartPie, faLayerGroup, faHome, faGripHorizontal,
      faCircle, faArrowAltCircleDown, faArrowAltCircleLeft, faSpinner, faCircleNotch, faTasks, farHdd, farHandshake, farEnvelope,
      farCheckCircle, farTimesCircle, faCaretDown, faCaretUp, faExclamationTriangle, faTable, faChartLine, faPause, faPlay, faBell, faTag,
      faFighterJet);
  }
}
