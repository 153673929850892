import { Component, Input } from '@angular/core';
import { ClusivityListItemsComponent } from '../clusivity-list-items.component';
import { TreeNode } from './clusivity-list-select-options-tree.models';

@Component({
  selector: 'app-clusivity-list-select-options-tree',
  templateUrl: './clusivity-list-select-options-tree.component.html',
  styleUrls: ['./clusivity-list-select-options-tree.component.less'],
  providers: [{
    provide: ClusivityListItemsComponent,
    useExisting: ClusivityListSelectOptionsTreeComponent
  }]
})
export class ClusivityListSelectOptionsTreeComponent<TItem> extends ClusivityListItemsComponent<TItem> {
  @Input() shouldSortOptions = false;
  @Input() selectElementId?: string;
  @Input() showSearch = true;
  @Input() checkable = true;
  @Input() dropdownStyle?: { [key: string]: string };
  @Input() maxTagCount?: number;
  @Input() placeHolder = '';
  private _options: TreeNode[] = [];

  get options(): TreeNode[] {
    return this._options;
  }

  @Input({ required: true })
  set options(value: TreeNode[]) {
    this._options = this.shouldSortOptions
      ? value
        .sort((a, b) => a.title.localeCompare(b.title))
        .map(parent => ({
          ...parent,
          children: parent.children?.sort((c1, c2) => c1.title.localeCompare(c2.title))
        }))
      : value;
  }
}
