<div class="partner-info">
    <!-- Form Content -->
    <form nz-form nzLayout="vertical" [formGroup]="form" (keydown.enter)="$event.preventDefault()">
      <!-- Internal Information Section -->
      <div class="sectionTitle">
        <fa-icon icon="info"></fa-icon>
        Internal Information
      </div>
      
      <nz-row nzGutter="16">
        <!-- Partner Name -->
        <nz-col nzLg="8" nzMd="8" nzSm="24">
          <nz-form-item>
            <nz-form-label nzRequired>Partner Name</nz-form-label>
            <nz-form-control [nzErrorTip]="'Partner name is required'">
              <input formControlName="name" nz-input placeholder="Enter partner name"
                     [maxlength]="256" autocomplete="off" required>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
  
        <!-- Platform Fee -->
        <nz-col nzLg="6" nzMd="6" nzSm="24">
          <nz-form-item>
            <nz-form-label nzRequired>Platform Fee</nz-form-label>
            <nz-form-control [nzErrorTip]="'Platform fee is required'">
              <nz-input-group nzAddOnAfter="%">
                <nz-input-number
                  formControlName="platformFee"
                  [nzStep]="1"
                  [nzPrecision]="2"
                  [nzMin]="0"
                  [nzMax]="99.99"
                  [nzPlaceHolder]="'Enter platform fee'"
                ></nz-input-number>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
  
        <!-- Platform Fee Type -->
        <nz-col nzLg="6" nzMd="6" nzSm="24">
          <nz-form-item>
            <nz-form-label nzRequired>Platform Fee Type</nz-form-label>
            <nz-form-control [nzErrorTip]="'Platform fee type is required'">
              <nz-select
                formControlName="platformFeeType"
                nzPlaceHolder="Select platform fee type"
                class="full-width">
                <nz-option 
                  *ngFor="let option of platformFeeOptions"
                  [nzValue]="option.value"
                  [nzLabel]="option.displayName">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
  
      <ng-container *ngIf="mode !== 'disabled'">
        <nz-row nzGutter="16">
          <!-- Internal Notes -->
          <nz-col nzLg="24" nzMd="24" nzSm="24">
            <nz-form-item>
              <nz-form-label>Internal Notes</nz-form-label>
              <nz-form-control>
                <textarea formControlName="notes" 
                         nz-input 
                         [nzAutosize]="{ minRows: 4, maxRows: 6 }"
                         placeholder="Enter internal notes">
                </textarea>
              </nz-form-control>
            </nz-form-item>
          </nz-col>
        </nz-row>
      </ng-container>
  
      <!-- Audience Section -->
      <div class="sectionTitle">
        <fa-icon icon="users"></fa-icon>
        Audiences
      </div>
      <nz-row nzGutter="16">
        <nz-col nzLg="24" nzMd="24" nzSm="24">
          <nz-form-item>
            <nz-form-label>Audience Providers</nz-form-label>
            <nz-form-control>
              <nz-select
                formControlName="audienceProviders"
                class="full-width"
                nzMode="multiple"
                nzPlaceHolder="Select providers"
                nzShowArrow="true"
                nzShowSearch>
                <nz-option 
                  *ngFor="let provider of dmpAudienceProviders" 
                  [nzLabel]="mode !== 'disabled' ? provider.providerName + ' (id: ' + provider.providerId + ')' : provider.providerName"
                  [nzValue]="provider.providerId">
                  {{ provider.providerName }} (id: {{ provider.providerId }})
                  <small class="provider-source">({{ provider.sourceDmp }})</small>
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
    </form>
  </div>

  <ng-template #hiddenFields></ng-template>
  <ng-template #hiddenNotes></ng-template>