<div class="collapse-filters">
  <nz-collapse [nzBordered]="false">
    <nz-collapse-panel
      [nzHeader]="collapsePanelHeader"
      [(nzActive)]="panels[0].active"
      [nzExpandedIcon]="expandedIconTpl">

      <ng-template #collapsePanelHeader>
        <span>Dayparting</span>
        <span *ngIf="!panels[0].active && hasSelectedDays"
              class="active-filters-badge">
          {{selectedDaysCount}} days selected
        </span>
      </ng-template>

      <ng-template #expandedIconTpl>
        <span class="small-font margin-right-small margin-left-small">
          <fa-icon icon="chevron-right" [rotate]="panels[0].active ? 90 : 0"></fa-icon>
        </span>
      </ng-template>

      <app-day-parting [formControl]="control">
      </app-day-parting>

    </nz-collapse-panel>
  </nz-collapse>
</div> 