import { Injectable } from '@angular/core';
import { ApiService } from '../../../_services/api.service';
import { CountryToRegionsMap, JapiQuery, Metro, ResponseFromServer, State } from 'src/app/shared/_models/models';
import { Observable } from 'rxjs';


@Injectable({providedIn: 'root'})

export class GeoTargetingService {

  constructor(
    private api: ApiService
  ) {
  }

  getRegions(query: JapiQuery): Observable<ResponseFromServer<State[]>> {
    return this.api.getRegions(query);
  }

  getCountryToRegions(query: JapiQuery): Observable<ResponseFromServer<CountryToRegionsMap>> {
    return this.api.getCountryToRegions(query);
  }

  getMetros(query: JapiQuery): Observable<ResponseFromServer<Metro[]>> {
    return this.api.getMetros(query);
  }

}
