export function convertMinutesToHours(minutes: number): number {
  return Math.ceil(minutes / 60);
}

export function convertHoursToMinutes(hours: number): number {
  return hours * 60;
}

export function normalizeTimeRange(startHour: number, endHour: number): {
  start: number;
  end: number;
} {
  const normalizedStart = startHour === 24 ? 0 : startHour;
  const normalizedEnd = endHour === 0 ? 24 : endHour;
  return { start: normalizedStart, end: normalizedEnd };
}

export function formatHourLabel(hour: number): string {
  return `${hour.toString().padStart(2, '0')}:00`;
}
