<form nz-form nzLayout="vertical" [formGroup]="formPart">
  <nz-row nzGutter="16">
    <nz-col nzLg="6" nzMd="6" nzSm="24">
      <app-clusivity-list
        formControlName="countriesClusiveList"
        label="Country"
        radioGroupElementId="geo-radio-country-blacklist-whitelist">
          <app-clusivity-list-select-options
            [options]="transformedCountries"
            selectListPlaceholderText="Select countries"
            maxSelectPreviewCount="2"
            allowClear
            selectElementId="package_countries"/>
      </app-clusivity-list>
    </nz-col>
    <nz-col nzLg="6" nzMd="6" nzSm="24">
      <app-clusivity-list
        formControlName="regionsClusiveList"
        label="Region/State"
        radioGroupElementId="geo-radio-region-blacklist-whitelist">
          <app-clusivity-list-select-options-tree
            [options]="regionsTree"
            placeHolder="Select regions/states"
            maxTagCount="2"
            selectElementId="package_regions"
            [dropdownStyle]="{ 'max-height': '300px' }"
            shouldSortOptions="true"/>
      </app-clusivity-list>
    </nz-col>

    <nz-col nzLg="6" nzMd="6" nzSm="24">
      <app-clusivity-list
        formControlName="metrosClusiveList"
        label="Metro"
        labelTooltipMessage="Metro can be selected only if United States is selected"
        radioGroupElementId="geo-radio-metro-blacklist-whitelist">
          <app-clusivity-list-select-options
            [options]="transformedMetros"
            selectListPlaceholderText="Select metros"
            maxSelectPreviewCount="2"
            allowClear
            selectElementId="package_metro"/>
      </app-clusivity-list>
    </nz-col>

    <nz-col nzLg="6" nzMd="6" nzSm="24">
      <app-clusivity-list
        formControlName="zipCodesClusiveList"
        [errorMessage]="zipCodesErrorMessage"
        label="Zip Codes"
        radioGroupElementId="geo-radio-zip-blacklist-whitelist">
          <app-clusivity-list-multi-text-input
            placeHolder="Use a comma or an enter to separate items in list"
            maxTagCount="2"
            selectElementId="package_postal_codes"/>
      </app-clusivity-list>
    </nz-col>
  </nz-row>
  </form>
